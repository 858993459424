import { IonCheckbox, IonLabel } from "@ionic/react";
import { RowWithDynamicGutter } from "./Grid";

export function CheckBoxRow(props: { label: string, checked: boolean, onChange: (checked: boolean) => void }) {
    function onChange(el: any) {
        props.onChange(el.detail.checked);
    }

    return <RowWithDynamicGutter>
        <IonCheckbox
            className='questionnaireCheckBoxOnboarding'
            checked={props.checked}
            onIonChange={onChange}
        />
        <IonLabel className='checkboxLabelOnboarding' color={"primary"}>{props.label}</IonLabel>
    </RowWithDynamicGutter>;
}

import { IonInput } from "@ionic/react";
import { OnboardMedia } from "../../../lib"
import FileUploadControl from "../../controls/shared/FileUpload";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useDebounce from "../../controls/hooks/useDebounce";

export type BusinessLicenseFileControlProps = {
    media: OnboardMedia,

    onFileChange: (media: OnboardMedia, file: File) => Promise<OnboardMedia>,
    onFileSelect: (fileName: string) => {},
    onDescriptionChange: (media: OnboardMedia) => Promise<OnboardMedia>,
}

export type BusinessLicenseFileControlRef = {
    isDescriptionSet: () => boolean,
    isFileSet: () => boolean,
} | null;

const BusinessLicenseFileControl = forwardRef<BusinessLicenseFileControlRef, BusinessLicenseFileControlProps>((props: BusinessLicenseFileControlProps, ref) => {
    const [media, setMedia] = useState<OnboardMedia>(props.media);

    useImperativeHandle(ref, () => ({
        isDescriptionSet: () => Boolean(media?.description && media.description.trim() !== ''),
        isFileSet: () => Boolean(media?.fileName && media.fileName.trim() !== ''),
    }));

    // description state is debounced, both states work together with the useEffect to react to the input change after 500ms inactivity
    const debouncedDescription = useDebounce<string | undefined>(media.description, 500);

    useEffect(() => {
        if (debouncedDescription) {
            // if the debounced description has changed, call the parent's implementation of onDescriptionChange
            // which will return a newly populated OnboardMedia we can use as the new state of this instance
            media.description = debouncedDescription;
            props.onDescriptionChange(media).then(result => setMedia(result));
        }
    }, [debouncedDescription]);

    async function changeFile(file: File) {
        var changedMedia = await props.onFileChange(media, file);
        setMedia(changedMedia);
        return changedMedia.fileName || '';
    }

    const control = <>

        <IonInput
            placeholder="Certificate Name/Reason"
            value={media.description}
            onIonChange={val => setMedia({ ...media, description: val.detail.value! })} />

        <FileUploadControl
            fileName={media?.fileName}
            onFileChange={changeFile}
            onFileSelect={props.onFileSelect} />

    </>;

    return control;
});

export default BusinessLicenseFileControl;
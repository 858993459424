import { IonButton, IonCol, IonGrid, IonImg, IonItem, IonList, IonRow, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { GetQuerystringValue, Onboarding, OnboardingBankAccount, PostBankAccountStarted, SetOnboardingBankAccountAsync, StepNames, StepStatus } from '../../../lib';
import { ListBankAccountsFromRequestIdAsync, RequestAccountInformationServicesUrlAsync } from '../../../lib/data/Ais';
import { SubmitButton } from '../../controls/shared/Buttons';
import { RowWithDynamicGutter } from '../../controls/shared/Grid';
import Spinner from '../../controls/shared/Spinner';
import './BankAccount.css';
import { PageAndContent } from '../../controls/nav/PageControls';

export type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function BankAccountStep(props: Props) {
    let accountInformationServiceCheck = props.onboarding.steps.get(StepNames.BankAccount);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [bankAccounts, setBankAccounts] = useState<OnboardingBankAccount[]>([]);

    async function startAis() {
        await PostBankAccountStarted();
        var url = await RequestAccountInformationServicesUrlAsync('/?ais=true');

        if (!url) {
            setErrorMessage("Could not start the bank account process");
            return; // alert?
        }

        window.location.href = url;
    };

    async function chooseBankAccount(bankAccount: OnboardingBankAccount) {
        await SetOnboardingBankAccountAsync(bankAccount);
        props.onNext();
    };

    async function mainProcess() {
        if (!accountInformationServiceCheck) return;

        if (accountInformationServiceCheck?.status >= StepStatus.Started) {
            let requestId = GetQuerystringValue("request-id");

            if (requestId) {
                var bankAccountResponse = await ListBankAccountsFromRequestIdAsync(requestId);

                if (bankAccountResponse.length === 1) {
                    chooseBankAccount(bankAccountResponse[0]);
                    return;
                }

                setBankAccounts(bankAccountResponse);
                return;
            }

            // if there is no request-id but this is "started" it must be a refresh and we'll have to try again
            startAis();
        }
    }

    useEffect(() => {
        mainProcess();
    }, []);

    function goBack() {
        props.onNext()
    }

    const multipleAccounts = <>
        <PageAndContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonText> <div className='ion-text-center'>Please choose a bank account from the list: </div></IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonList>
                {bankAccounts?.map((bankAccount, index) => {
                    return (
                        <IonItem
                            id={`bank_${bankAccount.accountNumber}`} key={bankAccount.accountNumber}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='1'></IonCol>
                                    <IonCol size='10'>
                                        <IonText>
                                            <div className='accountList'>

                                                {bankAccount.name}
                                                <br />
                                                ({bankAccount.accountHolderName})
                                                <br />
                                                {bankAccount.accountNumber} - {bankAccount.sortCode}
                                            </div>
                                        </IonText>

                                    </IonCol>
                                    <IonCol size='1'></IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs='2' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                                    <IonCol sizeXs='8' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4' >
                                        <IonButton color={"secondary"}
                                            className="accountSelectButton"
                                            onClick={() => chooseBankAccount(bankAccount)}> Choose Account &#187;
                                        </IonButton>
                                    </IonCol>
                                    <IonCol sizeXs='2' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>

                    );
                })}
            </IonList>
        </PageAndContent>
    </>;

    const content = <>
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className='smallLogo' src='./assets/img/logo.png' />
                    <h1 className='sectionTitle'>Bank Account Selection</h1>
                    <p className='onboardingSubText'>On the next screen you will be asked to select the bank account you wish to use, the chosen account will:</p>
                    <ul className='documentList'>
                        <li>Receive Payments</li>
                        <li>Pay for transaction fees</li>
                    </ul>

                    <div className="bottomButtonContainer">
                        <div className="bottomButtonRow">
                            <IonButton onClick={goBack}>Go Back &#187;</IonButton>
                            <SubmitButton
                                onClick={startAis}
                                text="Next &#187;" />
                        </div></div>
                    <p style={{ color: "#ff7100", fontSize: '13px' }} className='ion-text-center'>
                        <i>Your bank account information will not be shared with any unauthorised third parties</i>
                    </p>
                </RowWithDynamicGutter>
            </IonGrid>
        </PageAndContent>
    </>;

    function render() {
        if (errorMessage) return <p>{errorMessage}</p>;
        if (bankAccounts.length > 1) return multipleAccounts;
        if (accountInformationServiceCheck && accountInformationServiceCheck.status >= StepStatus.Started) return <Spinner />;
        return content;
    }

    return <>
        {render()}
    </>;
}
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRow, IonSpinner, IonText, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CreateStandingOrderPreset, DeleteStandingOrderPreset, StandingOrderFrequency, StandingOrderPayload, StandingOrderPreset, UpdateStandingOrderPreset } from "../../lib/data/StandingOrders";
import { ButtonRef, SubmitButton } from "../controls/shared/Buttons";
import { GlobalToastContext } from '../controls/shared/GlobalToastProvider';
import { RowWithDynamicGutter, RowWithSmallGutters } from "../controls/shared/Grid";
import { SelectInput, TextInput } from "../controls/shared/Inputs";
import { PageAndContent } from "../controls/nav/PageControls";

export default function StandingOrderPresetPage() {
    const history = useHistory();
    const globalToastContext = useContext(GlobalToastContext);
    const location = useLocation<StandingOrderPreset>();
    const passedState = location.state; // existing item passed in, to be used for comparison
    const [name, setName] = useState<string | undefined>(passedState?.name);
    const [payload, setPayload] = useState<StandingOrderPayload>(passedState?.payload || {});
    const buttonRef = useRef<ButtonRef>(null);
    const [formDisabled, setFormDisabled] = useState(true);
    const [editMode, setEditMode] = useState(!passedState);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const router = useIonRouter();

    useEffect(() => {
        if (!payload) return;

        // if we're in edit mode (passedState != null) then enable the save button when something changes
        // otherwise it only needs a name to be saved
        if (passedState) setFormDisabled((
            passedState.name === name &&
            passedState.payload.description === payload.description &&
            passedState.payload.amount === payload.amount &&
            passedState.payload.frequency === payload.frequency &&
            passedState.payload.numberOfPayments === payload.numberOfPayments
        ))
        else setFormDisabled(!(name));
    }, [payload, name, passedState]);

    function updatePayload<K extends keyof StandingOrderPayload>(
        property: K,
        value: StandingOrderPayload[K]) {
        setPayload(prev => ({
            ...prev,
            [property]: value,
        }));
    };

    async function createStandingOrderPreset() {
        if (!payload || !name) return;

        try {
            if (passedState) await UpdateStandingOrderPreset(name, payload, passedState.id);
            else await CreateStandingOrderPreset(name, payload);
            router.goBack();
        } catch (e) {
            globalToastContext.error("There was a problem creating the standing order preset. Please try again or contact us for support.");
            console.log(e);
        }
        finally {
            setEditMode(false);
        }
    }

    async function deletePreset() {
        try {
            setIsDeleting(true);
            await DeleteStandingOrderPreset(passedState.id);
            goBack();
        }
        finally {
            setShowDeleteModal(false);
            setIsDeleting(false);
        }
    }

    function goBack() {
        router.goBack();
    }

    const editForm = <>
        <RowWithSmallGutters>
            <TextInput
                onChange={(val) => setName(val)}
                value={name}
                label="Standing Order Preset Name"
                maxlength={30}
                readOnly={!editMode} />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <TextInput
                label='Payment Amount'
                onChange={(val) => updatePayload('amount', parseFloat(val || "0"))}
                value={payload?.amount?.toString()}
                type="number"
                readOnly={!editMode} />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <TextInput
                label='Description (optional)'
                onChange={(val) => updatePayload('description', val)}
                value={payload?.description}
                maxlength={50}
                readOnly={!editMode} />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <SelectInput
                label="Payment Frequency"
                selected={payload.frequency}
                onChange={(val) => updatePayload('frequency', val)}
                options={[
                    { value: StandingOrderFrequency.Weekly, label: "Weekly" },
                    { value: StandingOrderFrequency.Monthly, label: "Monthly" },
                    { value: StandingOrderFrequency.Annually, label: "Annually" }
                ]}
                readOnly={!editMode} />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <TextInput
                subLabel="Leave blank if there is no end date"
                label='Number of Payments'
                onChange={(val) => updatePayload('numberOfPayments', parseInt(val || "0"))}
                value={payload?.numberOfPayments?.toString()}
                type="number"
                readOnly={!editMode} />
        </RowWithSmallGutters>
    </>;

    const headerRightButtons = <>
        <div style={{ position: 'absolute', bottom: '8px', right: '15px' }}>
            {editMode && <IonButton
                color={'#fff'}
                className="circularButton"
                disabled={formDisabled}
                onClick={() => createStandingOrderPreset()}>
                <IonIcon
                    className="circularButtonContent"
                    src="assets\icon\checkmark.svg"
                />
            </IonButton>}
            {!editMode && <IonButton
                className="circularButton"
                style={{ backgroundColor: 'none' }}
                onClick={() => setEditMode(true)}
                color={'#fff'}>
                <IonIcon
                    className="circularButtonContent"
                    src="assets\icon\penIcon.svg" />
            </IonButton>}
            <IonButton onClick={() => setShowDeleteModal(true)} className="circularButton" color={'#fff'}>
                <IonIcon style={{ paddingRight: '0px' }} className="circularButtonContent" src="assets\icon\bin.svg" />
            </IonButton>
        </div>
    </>;

    const deleteModal = <>
        <IonModal className='smallModal' backdropDismiss={false} isOpen={true}>
            <IonContent className="modelContent">
                <IonText style={{ color: '#003466', textAlign: 'center' }}>
                    <b>
                        <div style={{ fontSize: '17px', marginTop: '8px' }}>
                            Are you sure you want to delete this preset?
                        </div>
                    </b>
                    <p style={{ fontWeight: '500' }}>This action cannot be undone</p>
                </IonText>
                <IonRow style={{ textAlign: 'center' }}>
                    <IonCol style={{ marginLeft: '-10px' }} className="bottomColButton" onClick={() => setShowDeleteModal(false)}>
                        <IonText style={{ fontSize: '17px', fontWeight: '400' }}>No</IonText>
                    </IonCol>
                    <IonCol style={{ marginRight: '-10px' }} className="bottomColButton" onClick={deletePreset}>
                        {isDeleting === true
                            ? <div><IonSpinner style={{ marginLeft: '-15px', color: '#fff' }} /></div>
                            : <IonText style={{ fontSize: '17px', fontWeight: '400' }}>Yes</IonText>}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonModal>
    </>;

    var toolbar = <>
        <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
            <IonButton onClick={goBack} className="circularButton">
                <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
            </IonButton>
        </div>
        <h2 className="titleBarText"> {passedState ? "Payment Preset" : "Create Preset"}</h2>

        {passedState && headerRightButtons}
    </>;

    return <>
        <PageAndContent toolbarChildren={toolbar}>
            <IonGrid>
                {editForm}

                {!passedState &&
                    <RowWithSmallGutters>
                        <SubmitButton text="Create Preset &#187;" onClick={() => createStandingOrderPreset()} />
                    </RowWithSmallGutters>}

                {passedState && <>
                    <RowWithDynamicGutter>
                        <SubmitButton
                            colour="secondary"
                            onClick={() => history.push('newstandingorder', passedState)}
                            text="Create Payment &#187;"
                            ref={buttonRef} />
                    </RowWithDynamicGutter>
                </>}
            </IonGrid>
        </PageAndContent>

        {showDeleteModal && deleteModal}
    </>;
}
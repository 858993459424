import { IonText, IonItem, IonCheckbox, IonLabel, IonButton, useIonRouter, IonImg } from "@ionic/react";
import { useContext, useState } from "react";
import { AccountContext } from "../../../contexts/AccountContext";
import { RequestAmex } from "../../../lib/data/CardsData";
import { SubmitButton } from "../../controls/shared/Buttons";
import { PageAndContent } from "../../controls/nav/PageControls";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";

export default function RequestAmexPage() {
    const router = useIonRouter();
    const accountContext = useContext(AccountContext);
    const [amexEnabled, setAmexEnabled] = useState(false);
    const [complete, setComplete] = useState(accountContext?.merchant?.paymentMethods?.ryft?.amexEnabled ?? false);
    const [errorMessage, setErrorMessage] = useState('');

    async function confirmAmex() {
        try {
            var response = await RequestAmex();

            if (response.success) {
                setComplete(true);
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function goHome() {
        accountContext?.refresh();
        router.goBack();
    }

    const applicationForm = <>
         <RowWithDynamicGutter>
                <IonImg className="smallLogo" src="./assets/img/logo.png" />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <h1 className="sectionTitle ion-margin-bottom">American Express Setup</h1>
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>

                <p className="onboardingSubText">American Express payments are subject to transaction fees of 2.99%</p>
                <p className="onboardingSubText">
                    Once you have reached a payout amount of £1 (after fees) your payout will be suspended until our card payment, Ryft, have performed their KYC checks on you and your business.
                </p>
                <p className="onboardingSubText">We may contact you for more information.</p>


        <IonItem lines="none">
            <IonCheckbox slot="start" checked={amexEnabled} onIonChange={e => setAmexEnabled(e.detail.checked)} />
           
            <p className="onboardingSubText">Tick this box to indicate that you agree to the <a href="https://ryftpay.com/terms-conditions" className="ion-text-primary" target="_blank" rel="noreferrer"><b>Ryft Terms of Service</b></a></p>
            
        </IonItem>

        {errorMessage && <IonText color="danger">{errorMessage}</IonText>}

        <div className="ion-margin-top ion-text-center">
            <SubmitButton onClick={() => confirmAmex()} text="Confirm" disabled={!amexEnabled} />
            <IonButton expand="block" onClick={() => router.goBack()}>Cancel</IonButton>
        </div>
        </RowWithDynamicGutter>
    </>;

    const completeMessage = <>
    <RowWithDynamicGutter>
                <IonImg className="smallLogo" src="./assets/img/logo.png" />
    </RowWithDynamicGutter>
    <RowWithDynamicGutter>
        <p className="onboardingSubText">Your customers now have the option to pay you with American Express</p>
        <p className="onboardingSubText">You can disable and enable the option in your Settings.</p>
        <IonButton color="tertiary" expand="block" onClick={() => goHome()}>Home</IonButton>
    </RowWithDynamicGutter>
    </>;

    return <>
        <PageAndContent>
            <div className="ion-padding">

                {complete ? completeMessage : applicationForm}
            </div>
        </PageAndContent>
    </>;
}

import { IonButton, IonGrid, IonImg, IonInput, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import { useState } from 'react';
import { AdditionalInformation, ChangeOnboardingMedia, DownloadOnboardingMedia, Onboarding, OnboardMediaType, OrgTypes, PostAdditionalInformationAsync, UploadOnboardingMedia } from '../../../lib';
import { SubmitButton } from '../../controls/shared/Buttons';
import FileUploadControl from '../../controls/shared/FileUpload';
import { RowWithDynamicGutter } from '../../controls/shared/Grid';
import './AdditionalInformation.css';
import { PageAndContent } from '../../controls/nav/PageControls';

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function AdditionalInformationStep(props: Props) {
    const [showOther, setShowOther] = useState<boolean>(false);
    const [additionalInformation, setAdditionalInformation] = useState<AdditionalInformation>(props.onboarding.businessDetails.additionalInformation ?? {} as AdditionalInformation);
    const [mediaUploaded, setMediaUploaded] = useState<boolean>(false);

    let complaintsComplete = additionalInformation.customerComplaintsProcess
        && (additionalInformation.customerComplaintsProcess !== "Other"
            || additionalInformation.customerComplaintsProcessOther > '');

    let isFormComplete = complaintsComplete
        && additionalInformation.customerComplaintsProcess > ''
        && additionalInformation.natureOfBusiness > ''
        && (props.onboarding.businessDetails.orgType !== OrgTypes.SoleTrader || mediaUploaded === true);

    async function onClickComplete(e: any) {
        await PostAdditionalInformationAsync(additionalInformation);
        props.onNext();
    }

    function onClickCustomerComplaintsProcess(Option: string) {
        setAdditionalInformation({ ...additionalInformation, customerComplaintsProcess: Option });

        if (Option === "Other") {
            setShowOther(true);
        }
        else {
            setShowOther(false);
        }
    };

    // todo: the following three functions are all shared with BigBusiness.tsx (and others?)
    function FindMediaTypeInModel(mediaType: number) {
        return props.onboarding.media?.find(m => m.type === mediaType);
    }

    function GetFileName(mediaType: number): string {
        var filename = FindMediaTypeInModel(mediaType)?.fileName;
        return (filename && filename > '') ? filename : '';
    }

    async function UploadSingleInstanceMedia(file: File, mediaType: number): Promise<string> {
        var id = FindMediaTypeInModel(mediaType)?.id;

        const uploadedFileName = (id && id > '')
            ? await ChangeOnboardingMedia(id, mediaType, file)
                .then((result) => result.fileName!)
            : await UploadOnboardingMedia(mediaType, file)
                .then((result) => result.fileName!);

        // Update the mediaUploaded state
        if (uploadedFileName) {
            setMediaUploaded(true);
        }

        return uploadedFileName;
    }

    function goBack() {
        props.onNext()
    }

    return <>
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className='smallLogo' src='./assets/img/logo.png' />
                </RowWithDynamicGutter>

                <RowWithDynamicGutter>
                    <h1 className='sectionTitle'>Additional Information</h1>

                    <p className='onboardingSubText' color='primary'>
                        Please share more details about your business
                    </p>
                </RowWithDynamicGutter>

                <RowWithDynamicGutter>
                    <div>
                        <div style={{ color: '#003466', marginTop: '-10px' }}>
                            <p className="natureTitle"><b>Nature of Organisation* </b></p>
                            <p style={{ fontSize: '13px', marginTop: '-10px' }}>e.g. Estate Agents, Locksmith</p>
                        </div>
                    </div>

                    <IonInput
                        style={{ marginBottom: '15px' }}
                        className='questionnaireInput'
                        type="text"
                        value={additionalInformation.natureOfBusiness}
                        onIonChange={(val) => setAdditionalInformation({ ...additionalInformation, natureOfBusiness: val.detail.value! })}>
                    </IonInput>

                    <div hidden={props.onboarding.businessDetails.orgType !== OrgTypes.SoleTrader}>
                        <div style={{ color: '#003466' }}><h5 style={{ fontSize: '14px' }}><b>UTR Number (Unique Taxpayer Reference)*</b></h5></div>

                        <div
                            style={{ color: '#a0a0a0' }}><p className='utrText'>Please attach a high quality image displaying your UTR Number clearly</p>
                        </div>

                        <br />

                        <div style={{ marginBottom: '15px' }} className="menuTitle">
                            <FileUploadControl
                                fileName={GetFileName(OnboardMediaType.UTR)}
                                onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.UTR)}
                                onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)} />
                        </div>
                    </div>

                    <div>
                        <div style={{ color: '#003466' }}><h5 className="natureTitle"><b>VAT Number &#40;if applicable&#41; </b></h5></div>
                    </div>

                    <IonInput
                        className='questionnaireInput'
                        type="text"
                        value={additionalInformation.vatNumber}
                        onIonChange={(val) => setAdditionalInformation({ ...additionalInformation, vatNumber: val.detail.value! })}>
                    </IonInput>

                    <div style={{ color: '#003466', marginTop: '15px' }}><h5 style={{ fontSize: '14px' }}><b>Customer Complaints Process*</b></h5></div>

                    <div style={{ color: '#a0a0a0' }}><h5 style={{ fontSize: '12px' }}>Select 'Other' if your process isn't an option</h5></div>

                    <IonList >
                        <IonSelect className='dropdownSelector' placeholder="Select" interface="alert"
                            onIonChange={(e) => {
                                onClickCustomerComplaintsProcess(e.detail.value);
                            }}
                            value={additionalInformation.customerComplaintsProcess}>
                            <IonSelectOption value="By Email">By Email</IonSelectOption>
                            <IonSelectOption value="By Post">By Post</IonSelectOption>
                            <IonSelectOption value="Over The Phone">By Phone</IonSelectOption>
                            <IonSelectOption value="Other">Other</IonSelectOption>
                        </IonSelect>
                    </IonList>

                    <br></br>

                    <IonInput
                        className='questionnaireInput'
                        type="text"
                        value={additionalInformation.customerComplaintsProcessOther}
                        hidden={!showOther}
                        onIonChange={(val) => setAdditionalInformation({ ...additionalInformation, customerComplaintsProcessOther: val.detail.value! })}
                        placeholder="Other">
                    </IonInput>
                    <div>
                        <div style={{ marginBottom: '-10px', marginTop: '20px', fontSize: '15px', color: '#003466' }}>*Required fields</div>
                    </div>
                    <div className="bottomButtonContainer">
                        <div className="bottomButtonRow">
                            <IonButton onClick={goBack}>Go Back &#187;</IonButton>
                            <SubmitButton
                                onClick={onClickComplete}
                                text="Complete &#187;"
                                disabled={!isFormComplete} />

                        </div>
                    </div>
                </RowWithDynamicGutter>
            </IonGrid>
        </PageAndContent>
    </>;
}
import { CheckboxChangeEventDetail, IonCheckbox } from "@ionic/react";
import { useEffect, useState } from "react";

interface GiftAidCheckboxProps {
    onGiftAidChange: (isChecked: boolean) => void;
}

export default function GiftAidCheckbox({ onGiftAidChange }: GiftAidCheckboxProps) {
    const [giftAidEnabled, setGiftAidEnabled] = useState<boolean>();

    useEffect(() => {
        onGiftAidChange(giftAidEnabled || false);
    }, [giftAidEnabled]);

    useEffect(() => {
        const initialGiftAidEnabled = localStorage.getItem("giftAidEnabled") === "true";
        setGiftAidEnabled(initialGiftAidEnabled);
    }, []);

    const handleGiftAidChange = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        const isChecked = event.detail.checked;
        setGiftAidEnabled(isChecked);
        localStorage.setItem("giftAidEnabled", isChecked.toString());
        onGiftAidChange(isChecked);
    };

    return (
        <div>
            <div style={{ display: "inline-flex", alignItems: "center", color: '#003466' }}>
                <IonCheckbox style={{ borderColor: "#CCC" }}
                    checked={giftAidEnabled}
                    onIonChange={handleGiftAidChange} 
                />
                &nbsp;Gift Aid
            </div>
        </div>
    );
}
import { ApiGet, ApiGetResponse, ApiPost, ApiPostResponse, ApiResponse, Director } from '..';

export type CardApplication = {
    status: CardsStatus,
    directorsAdded: number,
    directorsRemaining: number,
    directorsAvailable?: Director[],
};

export enum CardsStatus {
    Suppressed = -1,
    NotRequested = 0,
    AccountCreated = 10,
    VerificationRequired = 20,
    VerificationSubmitted = 30,
    VerificationFailed = 40,
    VerificationSucceeded = 50,
}

export const GetCardsApplication = async () : Promise<CardApplication> => {
    var response = await ApiGet("GetCardsApplication");
    return response.data;
}

export const StartCardsApplication = async () => {
    var response = await ApiPost("StartCardsApplication");
    return response.status >= 200 && response.status < 300;
}

export const RequestAmex = async () : Promise<ApiResponse> => {
    var response = await ApiPostResponse("RequestAmex");
    return response;
}

import { IonRow, IonCol, IonInput, IonModal, IonContent, IonDatetime, IonButton, DatetimeChangeEventDetail } from "@ionic/react";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";

export interface DatePickerRangeProps {
    startDate: Date | null,
    endDate: Date | null,
    onChange: (startDate: Date, endDate: Date) => void,
};

export default function DatePickerRange({startDate, endDate, onChange}: DatePickerRangeProps) {
    const [showDateRangeModel, setShowDateRangeModel] = useState<boolean>(false);
    const [dateStrings, setDateStrings] = useState<string[] | []>();
    const [start, setStart] = useState<string | undefined>(startDate ? format(startDate, "yyyy-MM-dd") : undefined);
    const [end, setEnd] = useState<string | undefined>(endDate ? format(endDate, "yyyy-MM-dd") : undefined);

    var currentDateStrings = [] as string[];

    useEffect(() => {
        if (start && end && start === end) {
            setDateStrings([start]);
        }
    }, [start, end])
    
    const onClickShowDateRangeModel = () => {
        setShowDateRangeModel(true);
    };

    const onClickDateRangeModel = () => {
        setShowDateRangeModel(false);
    };

    const closeDateRangeModel = () => {
        setShowDateRangeModel(false);
    }

    const dateChange = (selectedDate: string | string[] | null | undefined) => {
        if (!selectedDate) {
            currentDateStrings = [];
            setDateStrings(undefined);
            return;
        }

        if (selectedDate !== undefined && !Array.isArray(selectedDate)) return;

        var localDateStrings = selectedDate as string[];
        // Detect changes (prevents an infinite loop).
        if (currentDateStrings === localDateStrings) { return; }

        // Make sure we have at least two dates (otherwise nothing to do).
        if (!(localDateStrings && localDateStrings?.length > 1)) { return; }

        // Sort the dates so the earliest one is first.
        localDateStrings.sort((a, b) => {
            if (a > b) { return 1; }
            else if (a < b) { return -1; }
            else { return 0; }
        });

        // Revert to single selection (if required).
        if (dateStrings && dateStrings?.length > 2) {
            for (const d of dateStrings) {
                if (localDateStrings.indexOf(d) === -1) {
                    // Make sure this is not the first or last date (those are handled natively).
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                }
            }

            for (const d of localDateStrings) {
                if (!dateStrings.find(val => val === d)) {
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                }
            }
        }

        // Store the new value.
        const newValue = [];

        // Add all dates between the first and second dates.
        for (let d = new Date(localDateStrings[0]); d <= new Date(localDateStrings[localDateStrings.length - 1]); d.setDate(d.getDate() + 1)) {
            newValue.push(format(d, "yyyy-MM-dd"));
        }

        // Update the values at the end so the UI gets updated only once.
        currentDateStrings = newValue;
        setDateStrings(newValue);
        // Ref https://github.com/ionic-team/ionic-framework/issues/23572#issuecomment-1227543345
    }

    const onDateChanged = (val: CustomEvent<DatetimeChangeEventDetail>) => {
        var newDateStrings = val.detail.value;
        dateChange(newDateStrings);

        if (Array.isArray(newDateStrings)) {
            if (newDateStrings.length >= 1) {
                var newStart = newDateStrings[0];
                var newEnd = newDateStrings[newDateStrings.length - 1];

                setStart(newStart);
                setEnd(newEnd);

                var newStartDate = parseISO(newStart);
                var newEndDate = parseISO(newEnd);
                onChange(newStartDate, newEndDate);
            }
        }
    };

    return <>
        <IonRow>
            <IonCol sizeXs='6' sizeSm='6' sizeMd='6' sizeLg='6' sizeXl='6'>
                <IonInput value={start ? format(new Date(start), "dd-MM-yyyy") : ''}
                    placeholder="From"
                    onClick={onClickShowDateRangeModel}
                    className="datePicker">
                </IonInput>
            </IonCol>

            <IonCol sizeXs='6' sizeSm='6' sizeMd='6' sizeLg='6' sizeXl='6'>
                <IonInput value={end ? format(new Date(end), "dd-MM-yyyy") : ''}
                    placeholder="To"
                    onClick={onClickShowDateRangeModel}
                    className="datePicker">
                </IonInput>
            </IonCol>
        </IonRow>

        <IonModal handle={false} backdropDismiss={false} canDismiss={true} isOpen={showDateRangeModel} onDidDismiss={closeDateRangeModel} breakpoints={[0, 0.68]}
            initialBreakpoint={0.68} backdropBreakpoint={0.2}  >
            <IonContent className='dateTimeContent'>
                <br></br>
                <IonDatetime
                    className='datePicker'
                    multiple={true}
                    presentation="date"
                    value={dateStrings}
                    onIonChange={onDateChanged}>
                </IonDatetime>
                <IonButton style={{ width: '120px' }} size="small" shape="round" color="secondary" onClick={() => onClickDateRangeModel()}>Select</IonButton>
            </IonContent>
        </IonModal>
    </>;
}
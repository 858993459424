export default {
    Home: "/tabs/takePayment",
    PaymentHistory: "/tabs/paymentHistory",
    Settings: "/tabs/settings",
    CancelAccount: "/tabs/cancelAccount",
    ContactSupport: "/tabs/contactSupport",
    SubMerchantList: "/tabs/submerchants",
    NewSubMerchant: "/tabs/newsubmerchant",
    SubMerchant: "/tabs/submerchants/:subMerchantName",
    StandingOrdersPage: "/tabs/standingorders",
    NewStandingOrderPage: "/tabs/newstandingorder",
    StandingOrderPresetList: "/tabs/standingorderpresets",
    StandingOrderPresetPage: "/tabs/standingorderpreset",
    TransactionMonitoringPage: "/tabs/transactionmonitoring",
    DownloadDataControl: "/tabs/downloaddata",
    PaymentPresetsListPage: "/tabs/paymentpresets",
    PaymentPresetPage: "/tabs/paymentpreset"
}
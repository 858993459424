import {
    IonCol, IonContent, IonGrid,
    IonImg, IonModal, IonRouterLink, IonRow, IonText
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import { GetPaymentResult, PaymentRequest } from '../../lib';
import { useInterval } from '../controls/hooks/useInterval';
import PageSpinner from '../controls/shared/PageSpinner';
import './TakePaymentModal.css';
import './TakePaymentModalFailure';
import TakePaymentModalFailure from './TakePaymentModalFailure';
import './TakePaymentModalSuccess';
import TakePaymentModalSuccess from './TakePaymentModalSuccess';
import TakePaymentModalTimedOut from './TakePaymentModalTimedOut';
import ShareUrlButton from '../controls/shared/ShareUrlButton';

export interface TakePaymentProps {
    paymentRequest?: PaymentRequest,
    onComplete: Function,
}

export default function TakePaymentModal(props: TakePaymentProps) {
    const [paymentStatus, setPaymentStatus] = useState<string>("");
    const isStatusBeingChecked = useRef(true);

    useInterval(() => {
        if (isStatusBeingChecked.current === true && props.paymentRequest) {
            getPaymentResult();
        }
    }, 3000)

    // check the status once at the start to get things going
    useEffect(() => {
        if (props.paymentRequest) {
            getPaymentResult();
            isStatusBeingChecked.current = true;
        }
    }, [props.paymentRequest]);

    async function getPaymentResult() {
        var response = await GetPaymentResult(props.paymentRequest?.paymentId!);

        // updates may have been disabled between this call starting and now
        if (isStatusBeingChecked.current === false) {
            return;
        }

        setPaymentStatus(response.status)

        if (response.status === 'COMPLETE') {
            isStatusBeingChecked.current = false;
        }
    }

    function closeForm(e: any) {
        if (e) {
            e.preventDefault();
        }
        props.onComplete();
    };

    function paymentStatusScreen() {
        switch (paymentStatus) {
            case "COMPLETE":
                return <TakePaymentModalSuccess onClose={closeForm}></TakePaymentModalSuccess>

            case "FAILED":
                return <TakePaymentModalFailure onClose={closeForm}></TakePaymentModalFailure>

            case "TIMEDOUT":
                return <TakePaymentModalTimedOut onClose={closeForm}></TakePaymentModalTimedOut>

            case "":
                return <PageSpinner></PageSpinner>

            default:
                return <IonContent>

                    <IonRow className='modalTitleRow'>
                    </IonRow>

                    <IonGrid>
                        <IonRow>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                            <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6" class='ion-text-center'>
                                <IonText color='primary'>
                                    <h1 className='scanBelowText'>Scan below to pay</h1>
                                </IonText>
                            </IonCol>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class='ion-text-center'>
                                <IonText color='primary' className='ion-text-wrap'>
                                    <h2 className='takePaymentValue'><span className='takePaymentPound'>£</span>{props.paymentRequest?.amount.toFixed(2)}</h2>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"> </IonCol>
                            <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonImg src={`/api/qrcode?logo=tt1&content=${props.paymentRequest?.payLink}`} />
                            </IonCol>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3" > </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                            <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6">
                                <ShareUrlButton url={props.paymentRequest?.payLink!}
                                    buttonText="Share PayLink »" />
                            </IonCol >
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <IonCol class="ion-text-center" >
                                <IonRouterLink
                                    className='closeModal'
                                    style={{ textDecoration: "underline" }}
                                    onClick={closeForm}>
                                    <><b>Back</b></>
                                </IonRouterLink>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
        }
    }

    return <>
        <IonModal isOpen={true} backdropDismiss={false}>
            {paymentStatusScreen()}
        </IonModal>
    </>;
}



import { IonButton, IonCol, IonRow, IonText, IonToggle, useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { Merchant, SetCardPaymentsEnabled } from "../../lib/data/MerchantData";

type Props = {
    merchant: Merchant,
    refreshMerchant: Function,
};

export default function CardPaymentsSettings(props: Props) {
    const merchant = props.merchant;
    const [cardsEnabled, setCardsEnabled] = useState<boolean>(merchant.paymentMethods?.ryft?.enabled || false);
    const [amexEnabled, setAmexEnabled] = useState<boolean>(merchant.paymentMethods?.ryft?.amexEnabled || false);
    const router = useIonRouter();

    useEffect(() => {
        async function toggleCardPayments() {
            await SetCardPaymentsEnabled(cardsEnabled, amexEnabled);
            props.refreshMerchant();
        }

        if (merchant.paymentMethods?.ryft?.available &&
            (merchant.paymentMethods?.ryft?.enabled !== cardsEnabled ||
                merchant.paymentMethods?.ryft?.amexEnabled !== amexEnabled)) {
            toggleCardPayments();
        }
    }, [cardsEnabled, amexEnabled]);

    const cardPaymentsPanel = (
        <>
            {merchant.paymentMethods?.ryft?.available ? (
                <>
                    <IonRow>
                        <IonCol>
                            <span style={{ color: '#003466' }}>Enable Card Payments<br /></span>
                            <small style={{ color: '#a0a0a0' }}>Allow your customers to have the option to pay via card.</small>
                        </IonCol>
                        <IonCol size="auto">
                            <IonToggle checked={cardsEnabled} onIonChange={e => setCardsEnabled(e.detail.checked)}></IonToggle>
                        </IonCol>
                    </IonRow>
                </>
            ) : (
                <div>
                    <span style={{ color: '#003466' }}> Setup Card Payments</span><br />
                    <small style={{ color: '#a0a0a0' }}>Use the button below to give your customers the option to pay via card</small>
                    <IonButton onClick={() => router.push('/cards')} className="setupButton"
                        color={"secondary"}>
                        Set up Card Payments &#187;
                    </IonButton>
                </div>
            )}
        </>
    );

    const amexPanel = (
        <>
            {merchant.paymentMethods?.ryft?.amexAvailable ? (
                <>
                    <IonRow>
                        <IonCol>
                            <span style={{ color: '#003466' }}>Enable American Express Payments</span><br />
                            <small style={{ color: '#a0a0a0' }}>Allow your customers to have the option to pay via American Express.</small>
                        </IonCol>
                        <IonCol size="auto">
                            <IonToggle checked={amexEnabled}
                                onIonChange={e => setAmexEnabled(e.detail.checked)}
                                disabled={!merchant.paymentMethods?.ryft?.enabled}></IonToggle>
                        </IonCol>
                    </IonRow>
                </>
            ) : (
                <>
                    <span style={{ color: '#003466' }}>Setup Amex Payments</span><br />
                    <small style={{ color: '#a0a0a0' }}>Use the button below to give your customers the option to pay via American Express</small>
                    <IonButton onClick={() => router.push('/amex')}
                        className="setupButton"
                        color={"secondary"}
                        disabled={!merchant.paymentMethods?.ryft?.available}>
                        Set up Amex Payments &#187;
                    </IonButton>
                </>
            )}
        </>
    );

    return <>
        <h2 className="panelTitle">Card Payments</h2>
        {cardPaymentsPanel}
        {amexPanel}
    </>
}
import { IonButton, IonGrid, IonText, useIonRouter } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { appConfig, Merchant } from '../lib';
import { AccountContext } from '../contexts/AccountContext';
import { RowWithDynamicGutter, RowWithSmallGutters } from './controls/shared/Grid';
import './Settings.css';
import CardPaymentsSettings from './settings/CardPaymentsSettingsPanel';
import LogoUpload from './settings/LogoUpload';
import Logout from './settings/Logout';
import RedirectUrl from './settings/RedirectUrl';
import ClearMerchant from './settings/ResetAccount';
import VatNumber from './settings/VatNumber';
import DownloadDataControl from './settings/DownloadData';
import PageSpinner from './controls/shared/PageSpinner';
import ApiKeys from './settings/ApiKeys';
import { PageAndContent } from './controls/nav/PageControls';

const Settings = () => {
    const [merchant, setMerchant] = useState<Merchant | undefined | null>();
    const accountContext = useContext(AccountContext);
    const router = useIonRouter();

    async function refreshMerchant() {
        setMerchant(null);
        accountContext?.refresh();
    }

    useEffect(() => {
        setMerchant(accountContext?.merchant);
    }, [accountContext?.merchant])

    return <>{merchant ?
        <PageAndContent toolbarChildren={<IonText>Settings</IonText>}>
            <IonGrid>
                {accountContext?.account?.merchantType === "merchant"
                    && <>
                        <RowWithSmallGutters colClassName='greyPanel'>
                            <RedirectUrl merchant={merchant!} refreshMerchant={refreshMerchant} />
                        </RowWithSmallGutters>

                        <RowWithSmallGutters colClassName='greyPanel'>
                            <LogoUpload merchant={merchant!} refreshMerchant={refreshMerchant} />
                        </RowWithSmallGutters>

                        {merchant.paymentMethods?.ryft?.suppressed !== true &&
                            <RowWithSmallGutters colClassName='greyPanel'>
                                <CardPaymentsSettings merchant={merchant!} refreshMerchant={refreshMerchant} />
                            </RowWithSmallGutters>}

                        <RowWithSmallGutters colClassName='greyPanel'>
                            <VatNumber merchant={merchant!} refreshMerchant={refreshMerchant} />
                        </RowWithSmallGutters>
                    </>
                }

                {(accountContext?.account?.merchantType === "merchant" || accountContext?.account?.roles?.includes("admin")) && <>
                    <RowWithSmallGutters colClassName='greyPanel'>
                        <DownloadDataControl />
                    </RowWithSmallGutters>

                    <RowWithSmallGutters colClassName='greyPanel'>
                        <ApiKeys />
                    </RowWithSmallGutters>
                </>}

                <RowWithSmallGutters colClassName='greyPanel'>
                    <div className='panelTitle'>
                        Help
                    </div>
                    <p>
                        <a className='contactSupport ion-text-left' onClick={() => router.push("/tabs/ContactSupport")}>
                            <u>Contact Support</u>
                        </a>
                    </p>
                </RowWithSmallGutters>

                <RowWithSmallGutters colClassName='greyPanel'>
                    <div className='panelTitle' style={{ color: '#003466' }}>
                        About
                    </div>
                    <p>
                        <a href="https://trustisttransfer.com/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Service</a>
                    </p>
                    <p>
                        <a href="https://trustisttransfer.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                    </p>
                </RowWithSmallGutters>

                <RowWithDynamicGutter>
                    <Logout className='logOutButton' />
                </RowWithDynamicGutter>

                {(appConfig?.isDevelopment === true || appConfig?.isStaging === true) && <RowWithDynamicGutter><ClearMerchant /></RowWithDynamicGutter>}

                <RowWithSmallGutters>
                    <div className='cancelAccountButton' style={{ color: '#003466' }}>
                        <IonButton fill='clear' onClick={() => router.push("/tabs/CancelAccount")}><u>Cancel Account</u></IonButton>
                    </div>
                </RowWithSmallGutters>
            </IonGrid>
        </PageAndContent>
        :
        <PageSpinner />
    }</>;
}

export default Settings;

import { IonContent, IonGrid, IonRow, IonCol, IonImg, IonText, IonButton } from "@ionic/react"

export interface TakePaymentTimedOutProps {
    onClose : Function,
}

export default function TakePaymentModalTimedOut(props: TakePaymentTimedOutProps) {
 function closeForm(e : any) {
            props.onClose();
    }
    return <>

        <IonContent>
            
                
            <div className="toolbarBackground"> <IonImg src='/assets/img/background/topTrim.svg'/></div>
            
            <IonGrid>
                <IonRow className="imageRow">
                    <IonCol sizeXs="4" sizeSm="4.5" sizeMd="5" sizeLg="5" sizeXl="5"> </IonCol>
                    <IonCol sizeXs="4" sizeSm="3" sizeMd="2" sizeLg="2" sizeXl="2"> <IonImg src='assets/img/timedOut.png'/> </IonCol>
                    <IonCol sizeXs="4" sizeSm="4.5" sizeMd="5" sizeLg="5" sizeXl="5"> </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="2"> </IonCol>
                    <IonCol size="8">
                        <IonText><h5 className="statusConfirmationTitle">Payment Timed Out</h5></IonText>
                    </IonCol >
                    <IonCol size="2"> </IonCol>
                </IonRow>

                <IonRow></IonRow>

                <IonRow className="modalButtonRow">
                    <IonCol sizeXs="1" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2"> </IonCol>
                    <IonCol sizeXs="10" sizeSm="8" sizeMd="8" sizeLg="8" sizeXl="8" > <IonButton onClick={closeForm} color={'secondary'}>Take Another Payment &#187;</IonButton> </IonCol>
                    <IonCol sizeXs="1" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2"></IonCol>
                </IonRow>

                <IonRow></IonRow>
            </IonGrid>

        </IonContent>
    </>
}
import { IonSpinner } from '@ionic/react';
import './Spinner.css';

export type SpinnerParams = {
    show?: () => boolean | undefined,
};

export default function Spinner(props: SpinnerParams) {
    return <>{(typeof(props!.show) === 'undefined' || props.show()) ?
        <div className="spin">
            <IonSpinner />
        </div>
        :<></>}
    </>;
};
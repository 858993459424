import axios from "axios";
import { parseISO } from "date-fns";

export type AppConfig = {
    auth: {
        redirectUrl: string,
    },
    uptimia: {
        apiKey: string,
    },
    isDevelopment: boolean,
    isStaging: boolean,
    isProduction: boolean,
    isSandbox: boolean,
};

const axiosInstance = axios.create({
    baseURL: '/api',
});

// let config: AppConfig;
// let semaphore = false;

// export const GetConfig = async () => {
//     if (!config && !semaphore) {
//         semaphore = true; // mark awaited constructor
//         var response = await axiosInstance.get('config');
//         config = response.data;
//     }

//     return config;
// }

export const ApiGetBankHolidays = async () : Promise<Date[]> => {
    var result = await axiosInstance.get<string[]>('config/bankholidays');
    return result.data.map(b => parseISO(b));
}

interface AppConfigWindow extends Window {
    appConfig?: AppConfig;
}

export const appConfig = (window as unknown as AppConfigWindow).appConfig;
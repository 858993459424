import React, { useState, useRef } from 'react';
import { ButtonRef, SubmitButton } from './Buttons';

interface ShareButtonProps {
    url: string;
    buttonText: string,
    copyButtonText?: string,
    onSuccess?: () => void;
    onFailure?: (errorMessage: string) => void;
}

export default function ShareUrlButton ({ url, buttonText, onSuccess, onFailure, copyButtonText } : ShareButtonProps) {
    const shareButtonRef = useRef<ButtonRef>(null);
    const [shared, setShared] = useState<boolean>(false);
    const canShare = typeof navigator.share !== 'undefined' && !navigator.userAgent.includes("Macintosh");

    copyButtonText ||= "Copy To Clipboard";

    async function onClickShare(buttonRef: ButtonRef) {
        if (!url) {
            if (onFailure) onFailure("No content to share");
            buttonRef.stopSpinning();
            return;
        }

        if (canShare) {
            try {
                await navigator.share({
                    url: url,
                });

                setShared(true);
                if (onSuccess) onSuccess();
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(url);

                setShared(true);
                if (onSuccess) onSuccess();
            } catch (error) {
                console.error('Error copying content to clipboard:', error);
            }
        }
        buttonRef.stopSpinning();
    }

    return (
        <SubmitButton
            colour={(shared ? "primary" : "secondary")}
            class='shareButton'
            ref={shareButtonRef}
            onClick={() => onClickShare(shareButtonRef.current!)}
            text={canShare ? (shared ? "Re-" : "") + buttonText : copyButtonText} 
        />
    );
}
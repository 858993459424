import React, { Component, ErrorInfo } from 'react';

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

export class UptimiaErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Parse the component stack to get line numbers
        const stackLines = errorInfo.componentStack.split('\n');
        let scriptUrl = 'unknown';
        let lineNumber = 0;
        let columnNumber = 0;

        // Component stack format is typically:
        // "    at ComponentName (http://localhost:3000/static/js/bundle.js:1234:5)"
        for (const line of stackLines) {
            const match = line.match(/\((.*):(\d+):(\d+)\)/);
            if (match) {
                scriptUrl = match[1];
                lineNumber = parseInt(match[2], 10);
                columnNumber = parseInt(match[3], 10);
                break;  // Use the first match (closest to error)
            }
        }

        // Use the existing global error reporting function
        (window as any).reportRUMUptimia(
            error.message,
            scriptUrl,
            lineNumber,
            columnNumber,
            error
        );

        // Also log the full component stack for debugging
        console.error('React Error:', {
            message: error.message,
            componentStack: errorInfo.componentStack,
            fullError: error
        });
    }

    render() {
        // if (this.state.hasError) {
        //     // You can render any fallback UI here
        //     return <h1>Something went wrong.</h1>;
        // }

        return this.props.children;
    }
} 
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonModal, IonRow } from "@ionic/react";
import { colorFill, eggOutline, helpCircle, helpOutline } from "ionicons/icons";
import { RowWithDynamicGutter } from "./Grid";
import { OverlayEventDetail } from "@ionic/core";
import { useState } from "react";
import EmailButton from "./EmailButton";


interface HelpModalProps {
    modalMessage: string;
}

const HelpModal: React.FC<HelpModalProps> = ({ modalMessage }) => {

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);

    };

    const closeModal = () => {
        setShowModal(false);
    }




    return <>


        <IonButton className="helpModalButton" onClick={openModal} fill="clear"><IonIcon className="top-right-icon" style={{ color: '#003466' }} src='./assets/icon/questionMark.svg' size="large" ></IonIcon></IonButton>


        <IonModal className="helpModal" isOpen={showModal} onDidDismiss={closeModal}>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2' />
                        <IonCol sizeXs='12' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='8'>
                            <h1 className='modalHeader' style={{marginTop: "30px"}}>Contact Support</h1>
                            <p className="modalMainText ion-text-center">
                                Our support team is here to help you. Please get email us at <u>customerservice@trustisttransfer.com</u> with a brief description of your issue or question, and we'll get back to you as soon as possible.
                            </p>
                        </IonCol>
                        <IonCol sizeXs='0' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2' />
                    </IonRow>
                    <div className="closeModalButton">
                        <IonRow>
                            <IonCol sizeXs='1' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                            <IonCol sizeXs='10' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='8'>
                                <EmailButton />
                                <IonButton fill="clear" onClick={closeModal}><u>Back to Menu</u></IonButton>
                            </IonCol>
                            <IonCol sizeXs='1' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'></IonCol>
                        </IonRow>
                    </div>
                </IonGrid>
            </IonContent>
        </IonModal>


    </>
};


export default HelpModal;
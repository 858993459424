import { IonButton, IonCol, IonRow, useIonRouter } from '@ionic/react';
import { GridWithRowAndMiddleColumn } from '../controls/shared/Grid';
import './CancelAccount.css';
import { PageAndContent } from '../controls/nav/PageControls';

export default function CancelAccount() {

    const router = useIonRouter();
    
    return <>
        <PageAndContent>
            <GridWithRowAndMiddleColumn>
            <h1 className='cancelTitleRow'>Account Cancellation</h1>
            <h5 className='cancelTextRow'>To cancel your account, please contact us at customerservice@trustisttransfer.com</h5>
            <IonRow>
                <IonCol sizeXs='0' sizeSm='1' sizeMd='2' sizeLg='3' sizeXl='4' />
                <IonCol sizeXs='12' sizeSm='10' sizeMd='8' sizeLg='6' sizeXl='4'>
                    <IonButton onClick={() => router.push("/tabs/settings")}>
                        Go Back &#187;
                    </IonButton>
                </IonCol>
                <IonCol sizeXs='0' sizeSm='1' sizeMd='2' sizeLg='3' sizeXl='4' />
            </IonRow>
            </GridWithRowAndMiddleColumn>
        </PageAndContent>
    </>;
}

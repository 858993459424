import { ApiFileDownload, ApiGet, ApiPost, ApiPut, BankAccount } from "..";

export type Onboarding = {
    steps: Map<string, Step>,
    bankAccount?: BankAccount,
    requiresManualIntervention: boolean,
    businessDetails: BusinessDetails,
    applicant: Applicant,
    media: OnboardMedia[],
    kycMode: KycMode,
    payments: OnboardPayments,
};

export enum KycMode {
    Unknown = 0,
    BusinessBeneficiaries = 1,
    BusinessDirector = 2,    
}

export const StepNames = {
    GettingStarted: "gettingStarted",
    OnboardSession: "onboardSession",
    Questionnaire: "questionnaire",
    DirectDebitMandate: "directDebitMandate",
    BankAccount: "bankAccount",
    AboutBusiness: "aboutBusiness",
    NominateApplicant: "nominateApplicant",
    DirectorCheck: "directorCheck",
    AdditionalInformation: "additionalInfo",
    CompaniesHouse: "companiesHouse",
    BigBusiness: "bigBusiness",
    Complete: "complete",
    PaymentTypes: "paymentTypes",
} as const;

export const OrgTypes = {
    SoleTrader: "soleTrader",
    LimtedCompany: "limitedCompany",
    LimitedLiabilityPartnership: "limitedLiabilityPartnership",
    PublicSectorOrganisation: "publicSectorOrganisation",
    Foundation: "foundation",
    Charity: "charity",
    CIO: "cio",
    Trust: "trust",
    Other: "other",
} as const;

export const OrgTypesDisplayText = new Map<string, string>([
    [OrgTypes.SoleTrader, "Individual or Sole Trader"],
    [OrgTypes.LimtedCompany, "Limited Company (e.g. PLC)"],
    [OrgTypes.LimitedLiabilityPartnership, "Limited Liability Partnership"],
    [OrgTypes.CIO, "Charitable Incorporated Organisation"],
    // [OrgTypes.PublicSectorOrganisation, "Public Sector Organisation"],
    // [OrgTypes.Foundation, "Foundation"],
    // [OrgTypes.Charity, "Charity"],
    // [OrgTypes.Trust, "Trust"],
    // [OrgTypes.Other, "Other"]
    
]);

export interface Step {
    status: StepStatus,
};

export enum StepStatus {
    Pending = 10,
    Started = 20,
    Waiting = 30,
    Failed = 50,
    Complete = 100,
}

export type Applicant = {
    role: string,
    phoneNumber: string,
};

export type BusinessDetails = {
    orgType?: string,
    orgTypeOther?: string,
    companiesHouseNumber: string,
    beneficiaries: Beneficiary[],
    directors: Director[],
    address?: Address,
    businessSize: BusinessSize,
    companyName: string,
    website: string,
    additionalInformation: AdditionalInformation,
    onCompaniesHouse?: boolean,
};

export type BusinessSize = {
    overTenMillionTurnover?: boolean,
    overFiveMillionBalance?: boolean,
    overFiftyEmployees?: boolean,
};

export type Beneficiary = {
    id: string,
    firstName: string,
    lastName: string,
    address: Address,
    isApplicant: boolean,
    shareLink: string,
    onboardSession?: OnboardSession,
};

export type Director = {
    id: string,
    name: string,
    address: Address,
    isApplicant: boolean,
    shareLink: string,
    onboardSession?: OnboardSession,
};

export type OnboardSession = {
    created?: Date,
    updated?: Date,
    media: OnboardMedia[],
    status: OnboardStatus,
    attemptCount: number,
};

export type Address = {
    addressLine1: string,
    addressLine2: string,
    city: string,
    country: string,
    postalCode: string,
};

export type AdditionalInformation = {
    customerComplaintsProcess: string,
    customerComplaintsProcessOther: string,
    natureOfBusiness: string,
    vatNumber: string,
};

export type GettingStarted = {
    complete: boolean,
};

export type GettingStartedQuestions = {
    orgType?: string,
    orgTypeOther?: string,
    overTenMillionTurnover?: boolean,
    overFiveMillionBalance?: boolean,
    overFiftyEmployees?: boolean,
};

export type AboutBusinessQuestions = {
    phoneNumber?: string,
    website?: string,
    companyName?: string,
    applicantRole?: string,
};

export enum OnboardStatus {
    Pending = 10,
    Started = 20,
    Waiting = 30,
    Failed = 50,
    Complete = 100,
};

export const OnboardMediaType = {
    CertificateOfIncorporation: 10,
    ArticlesOfAssociation: 20,
    GroupStructureChart: 30,
    AmlPolicy: 40,
    AntiBriberyPolicy: 50,
    DataProtectionPolicy: 60,
    SecurityPolicy: 70,
    UTR: 80,
    Certificate: 90,
    Other: 100,
} as const;

export type OnboardMedia = {
    id?: string,
    description?: string,
    fileName?: string,
    contentType?: string,
    downloadUrl?: string,
    type?: number,
};

export type OnboardingBankAccount = {
    externalId: string | null, // not populated for manual accounts
    name: string,
    accountHolderName: string | null,
    accountNumber: string | null,
    sortCode: string | null,
    iban: string | null,
    bankId: string | null, // not populated for manual accounts
};

export type OnboardPayments = {
    paymentTypes: string[],
};

export const OnboardingPaymentTypes = {
    OpenBanking: "openBanking",
    Cards: "cards",
    Amex: "amex",
    DenyCards: "denyCards"
};

export const SaveAboutBusiness = async (aboutBusiness: AboutBusinessQuestions) => {
    var response = await ApiPost('AboutBusiness', aboutBusiness);
    return response.status === 200;
}

export const PostAdditionalInformationAsync = async (data: AdditionalInformation) => {
    var response = await ApiPost("PostAdditionalInformation", data);
    return response.status === 200;
}

export const GetOnboardingAsync = async (): Promise<Onboarding | null> => {
    let response = await ApiGet("GetOnboarding");
    return response.status === 200
        ? {
            ...response.data,
            steps: new Map(Object.entries(response.data.stepData)),
        }
        : null;
};

export const SetOnboardingBankAccountAsync = async (bankAccount: OnboardingBankAccount) => {
    var response = await ApiPost("PostOnboardingBankAccount", bankAccount);
    return response.status === 200;
};

export const SetTestOnboardingBankAccountAsync = async () => {
    var response = await ApiPost("PostTestOnboardingBankAccount", {});
    return response.status === 200;
};

export const ConfirmDirectDebitAsync = async (address: Address): Promise<Onboarding | undefined> => {
    var response = await ApiPost("ConfirmDirectDebit", address);
    return response.status === 200
        ? {
            ...response.data,
            steps: new Map(Object.entries(response.data.stepData)),
        }
        : undefined;
};

export const GetUtrFile = async (): Promise<any> => {
    var response = await ApiGet("GetUtrFile", { responseType: 'arraybuffer' });
    return response.status === 200 ? response.data : undefined;
}

export const SetManualOnboarding = async (): Promise<boolean> => {
    var response = await ApiPost("SetManualOnboarding", {});
    return response.status === 200;
}

// beneficiary can be null to represent applicant not appearing in list of owners
export const NominateApplicant = async (beneficiary?: Beneficiary, director?: Director): Promise<boolean> => {
    var response = await ApiPost(
        "SetApplicantRole",
        {
            beneficiaryId: beneficiary?.id,
            directorId: director?.id,
        });
        return response.status >= 200 && response.status < 300;
}

export const GetOnboardUrl = async (): Promise<string> => {
    var response = await ApiGet("GetOnboardUrl");
    return response.status === 200 ? response.data : undefined;
}

export async function SetGettingStartedQuestions(questions: GettingStartedQuestions): Promise<boolean> {
    var response = await ApiPost("SetGettingStartedQuestions", questions);
    return response.status === 200;
}

export async function UploadOnboardingMedia(type: number, file?: File, description?: string): Promise<OnboardMedia> {
    const formData = new FormData();
    formData.append('type', type.toString());
    if (file) formData.append('file', file, file.name);
    if (description) formData.append('description', description);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    var response = await ApiPost("OnboardingMedia", formData, config);
    return response.status === 200 ? response.data : null;
}

export async function ChangeOnboardingMedia(id: string, type: number, file?: File, description?: string): Promise<OnboardMedia> {
    const formData = new FormData();
    formData.append('type', type.toString());
    if (file) formData.append('file', file, file.name);
    if (description) formData.append('description', description);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    var response = await ApiPut("OnboardingMedia/" + id, formData, config);
    return response.status === 200 ? response.data : null;
}

export async function DownloadOnboardingMedia(id: string): Promise<boolean> {
    await ApiFileDownload("OnboardingMedia/" + id);
    return true;
}

export async function BigBusinessPost(sanctionsText?: string): Promise<boolean> {
    var response = await ApiPost("BigBusiness", { sanctionsText });
    return response.status === 200 ? response.data : false;
}

export const CompleteOnboarding = async (): Promise<boolean> => {
    var response = await ApiPost("CompleteOnboarding");
    return response.status === 200;
}

export const SendOnboardLink = async (personId: string, emailAddress: string): Promise<boolean> => {
    var response = await ApiPost("SendOnboardLink", { personId, emailAddress });
    return response.status === 200;
}

export const PostBankAccountStarted = async (): Promise<boolean> => {
    var response = await ApiPost("PostBankAccountStarted", {});
    return response.status === 200;
}

export const SetOnboardingPaymentTypes = async (paymentTypes: string[], finaliseStep: boolean = false): Promise<boolean> => {
    var response = await ApiPost("SetOnboardingPaymentTypes", { paymentTypes, finaliseStep });
    return response.status === 200;
}

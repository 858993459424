import React, { createContext, useContext, useCallback } from 'react';
import { appConfig } from '../lib/util/Config';

function generateSessionId(length: number = 32): string {
    if (!('crypto' in window) || !Array.from) return '';
    const array = new Uint8Array(length / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array)
        .map(b => ('0' + b.toString(16)).substr(-2))
        .join('');
}

interface TimingMetrics {
    domtm: number;
    rendertm: number;
    connecttm: number;
    waittm: number;
    transfertm: number;
}

interface UptimiaContextType {
    reportPageViewToUptimia: (url: string, timing: TimingMetrics) => void;
}

const UPTIMIA_KEY = appConfig?.uptimia?.apiKey || "8ec109037031487fa0a5fc28a85138e9";
const UPTIMIA_ENDPOINT = `https://www.uptimiarum.eu/rum-receive/${UPTIMIA_KEY}`;
const SESSION_ID = generateSessionId();

const UptimiaContext = createContext<UptimiaContextType | null>(null);

export function UptimiaProvider({ children }: { children: React.ReactNode }) {
    const reportPageViewToUptimia = useCallback((url: string, timing: TimingMetrics) => {
        const navigationInfo = {
            userAgent: window.navigator.userAgent,
            language: window.navigator.language || '',
            platform: window.navigator.platform || '',
            appName: window.navigator.appName || '',
            appVersion: window.navigator.appVersion || ''
        };

        const formData = new URLSearchParams();
        formData.append('navi', JSON.stringify(navigationInfo));
        Object.entries(timing).forEach(([key, value]) => {
            formData.append(key, String(value));
        });
        formData.append('url', url);
        formData.append('session_id', SESSION_ID);
        formData.append('dnstm', '0');
        formData.append('redirecttm', '0');

        const xhr = new XMLHttpRequest();
        xhr.open('POST', UPTIMIA_ENDPOINT, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        xhr.send(formData.toString());
    }, []);

    return (
        <UptimiaContext.Provider value={{ reportPageViewToUptimia }}>
            {children}
        </UptimiaContext.Provider>
    );
}

export function useUptimia() {
    const context = useContext(UptimiaContext);
    if (!context) {
        throw new Error('useUptimia must be used within an UptimiaProvider');
    }
    return context;
}

// For API calls
export function reportUptimiaEvent(url: string) {
    const startTime = performance.now();
    
    // Return a function to be called when the API call completes
    return {
        success: () => {
            const endTime = performance.now();
            const elapsed = endTime - startTime;
            
            const formData = new URLSearchParams();
            formData.append('url', `/api/${url}`);
            formData.append('session_id', SESSION_ID);
            // API calls are mostly waiting and transfer
            formData.append('waittm', String(Math.round(elapsed * 0.7))); // 70% waiting for response
            formData.append('transfertm', String(Math.round(elapsed * 0.3))); // 30% receiving data
            // Set other timings to minimal values
            formData.append('domtm', '1');
            formData.append('rendertm', '1');
            formData.append('connecttm', '1');
            formData.append('dnstm', '0');
            formData.append('redirecttm', '0');
            
            const xhr = new XMLHttpRequest();
            xhr.open('POST', UPTIMIA_ENDPOINT, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xhr.send(formData.toString());
        }
    };
}

// Add setup function to initialize error handling
export function setupUptimiaErrorHandling() {
    (window as any).onerror = function(error: any, scriptUrl: string, lineNumber: number, columnNumber: number, errorObj: Error) {
        setTimeout(function() {
            if (typeof (window as any).reportRUMUptimia !== "undefined") {
                (window as any).reportRUMUptimia(error, scriptUrl, lineNumber, columnNumber, errorObj);
            }
        }, 1000);
    };

    (window as any).reportRUMUptimia = function(error: any, scriptUrl: string, lineNumber: number, columnNumber: number, errorObj: Error) {
        const data = `error=${error}&script_url=${scriptUrl}&line_number=${lineNumber}&column_number=${columnNumber}&session_id=${SESSION_ID}`;
        
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `https://www.uptimiarum.eu/rum-receive-errors/${UPTIMIA_KEY}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        xhr.send(data);
    };
} 
import { IonContent, IonGrid, IonImg, IonPage, IonText, IonToolbar, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { CreateSubMerchant, Merchant, SendSubMerchantInviteEmail } from "../../lib";
import { AccountContext } from "../../contexts/AccountContext";
import { ButtonRef, SubmitButton, TextButton } from "../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../controls/shared/Grid";
import { TextInput } from "../controls/shared/Inputs";
import { PageAndContent } from "../controls/nav/PageControls";

type SetInviteFlagDelegate = (flag: boolean) => void;


export default function NewSubMerchant() {
    const [displayName, setDisplayName] = useState<string | undefined>();
    const [inviteViaEmail, setInviteViaEmail] = useState(false);
    const [inviteViaQrCode, setInviteViaQrCode] = useState(false);
    const [inviteViaShare, setInviteViaShare] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailAddress, setEmailAddress] = useState<string | undefined>();
    const [inviteUrl, setInviteUrl] = useState<string>();
    const lockControls = useRef(false);
    const canShare = typeof navigator.share !== 'undefined';
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [merchant, setMerchant] = useState<Merchant | undefined | null>();
    const [duplicateDisplayName, setDuplicateDisplayName] = useState(false);
    const [errorReturned, setErrorReturned] = useState(false);
    const [emailErrorReturned, setEmailErrorReturned] = useState(false);
    const buttonRef = useRef<ButtonRef>(null);
    const accountContext = useContext(AccountContext);
    const router = useIonRouter();

    useEffect(() => {
        const emailRegex = /\S+@\S+\.\S+/;
        setIsEmailValid(emailRegex.test(emailAddress || ''));
    }, [emailAddress]);

    useEffect(() => {
        setMerchant(accountContext?.merchant);
    }, [accountContext?.merchant])

    useEffect(() => {
        if (merchant?.submerchants && merchant.submerchants.length > 0) {
            const entriesAreValid = merchant.submerchants.some(subMerchant => subMerchant.name === displayName);
            setDuplicateDisplayName(entriesAreValid);
        }
    }, [displayName]);


    async function createSubMerchantAndUpdateForm(setInviteFlagDelegate: SetInviteFlagDelegate, isShareDelegate: boolean = false) {
        if (!lockControls.current) {
            lockControls.current = true;

            try {
                // create the submerchant and set its url
                var createResult = await CreateSubMerchant(displayName!);
                if (!createResult) {
                    setErrorReturned(true);
                    lockControls.current = false;
                    buttonRef.current?.stopSpinning(true);
                }
                accountContext?.refresh();
                setInviteUrl(createResult?.inviteUrl);
                setErrorReturned(false);

                // if this is a share, handle the click
                // necessary due to an api restriction that disallows programmatic share requests
                if (isShareDelegate === true) {
                    handleShareClick(createResult?.inviteUrl!);
                }

                // show the appropriate follow-up screen
                setInviteFlagDelegate(true);
            }
            catch {
                setErrorReturned(true);
                lockControls.current = false;
                buttonRef.current?.stopSpinning(true);
            }

        }
    }

    function ClearFormsAndRedirect() {
        setDisplayName(undefined);
        setEmailAddress(undefined);
        setInviteViaEmail(false);
        setInviteViaQrCode(false);
        setInviteViaShare(false);
        setEmailSent(false);
        lockControls.current = false;
        router.push('/tabs/submerchants');
    }

    function UseEmailInstead() {
        setDisplayName(displayName);
        setEmailAddress(undefined);
        setInviteViaEmail(true);
        setInviteViaQrCode(false);
        setInviteViaShare(false);
        setEmailSent(false);
        lockControls.current = false;
        router.push('/tabs/newsubmerchant');
    }

    async function sendEmailInvite() {

        try {
            await SendSubMerchantInviteEmail(displayName!, emailAddress!);
            setEmailSent(true);
            setEmailErrorReturned(false)
        }
        catch (error) {
            setEmailErrorReturned(true)
            buttonRef.current?.stopSpinning(true)
        }
    }

    async function handleShareClick(url: string) {
        if (canShare) {
            try {
                await navigator.share({
                    url,
                });
            } catch (error) {
                console.error('Error sharing link:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(url);
            } catch (error) {
                console.error('Error copying link to clipboard:', error);
            }
        }
    };

    const initialForm = <>
        <RowWithDynamicGutter>
            <h2 id="displayNameTitle"><b>Display Name</b></h2>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <p className="displayNameText">Please enter a recognisable name to the subaccount, this can be a location name or team member name. For example:</p>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <p className="displayNameText">Team member name - Location</p>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <TextInput
                placeholder='Display Name'
                onChange={(val) => setDisplayName(val)}
                value={displayName} />
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <p id="displayNameErrorMessage" hidden={!duplicateDisplayName}>This Subaccount name already exists</p><br></br>
            <p id="networkErrorMessage" hidden={!errorReturned}>There has been an error, please try again. <br></br><br></br>If the error persists, please email customerservice@trustist.com</p>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <SubmitButton ref={buttonRef} text='Invite Via QR Code &#187;' disabled={!displayName || duplicateDisplayName} onClick={() => createSubMerchantAndUpdateForm(setInviteViaQrCode)}></SubmitButton>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <SubmitButton ref={buttonRef} text='Share Link &#187;' disabled={!displayName || duplicateDisplayName} onClick={() => createSubMerchantAndUpdateForm(setInviteViaShare, true)}></SubmitButton>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <TextButton disabled={!displayName || duplicateDisplayName} onClick={() => createSubMerchantAndUpdateForm(setInviteViaEmail)}>Invite Via Email &#187;</TextButton>
        </RowWithDynamicGutter>
    </>;

    const qrCodeForm = <>
        <RowWithDynamicGutter>
            <IonImg src={`/api/qrcode?logo=tt1&content=${inviteUrl}`} />
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <SubmitButton text="Done &#187;" onClick={() => ClearFormsAndRedirect()}></SubmitButton>
            <TextButton onClick={UseEmailInstead}>Invite Via Email &#187;</TextButton>
        </RowWithDynamicGutter>
    </>;

    const emailForm = <>
        <RowWithDynamicGutter>
            <p id="subMerchantEmailTitle"><b>Email Address</b></p>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <p id="subMerchantEmailText">Please enter the email address of the person you want to add as a subaccount holder.</p>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <p id="networkErrorMessage" hidden={!emailErrorReturned}>There has been an error, please try again. <br></br><br></br>If the error persists, please email customerservice@trustist.com</p>
            <p id="emailErrorMessage" hidden={isEmailValid}>Please enter a valid email address</p>

        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <TextInput
                placeholder='name@domain.com'
                onChange={val => setEmailAddress(val)}
                value={emailAddress} />
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            {emailSent
                ? <>
                    <p hidden={!emailSent} className="center successGreen">Invite Sent</p>
                    <SubmitButton text="Finish &#187;" onClick={() => ClearFormsAndRedirect()}></SubmitButton>
                </>
                : <>
                    <SubmitButton ref={buttonRef} disabled={!isEmailValid} text="Send Invite &#187;" onClick={() => sendEmailInvite()} colour="primary"></SubmitButton>
                </>
            }
        </RowWithDynamicGutter>
    </>;

    const shareForm = <>

        <RowWithDynamicGutter>
            <IonImg className="failOrSuccessImage" src="./assets/img/successTick.png" />
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <h1 className="mainHeader">Subaccount Invited!</h1>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <IonText>
                <p className="center">
                    You can see the status of their account in the subaccounts tab.
                </p>
                <p className="center">
                    You can re-invite team members from the subaccounts tab if required.
                </p>
            </IonText>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <SubmitButton text="Done &#187;" onClick={() => ClearFormsAndRedirect()}></SubmitButton>
        </RowWithDynamicGutter>
    </>;

    const copiedForm = <>
        <RowWithDynamicGutter>
            <IonImg className='smallLogo' src='./assets/img/logo.png' />
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <IonImg className="failOrSuccessImage" src="./assets/img/successTick.png" />
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <h1 className="mainHeader">Invite Link Copied!</h1>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <IonText>
                <p className="center">
                    Once the invite link is clicked you can see the status of the account in the subaccounts tab.
                </p>
                <p className="center">
                    You can re-invite team members from the subaccounts tab if required.
                </p>
            </IonText>
        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <SubmitButton text="Done &#187;" onClick={() => ClearFormsAndRedirect()}></SubmitButton>
        </RowWithDynamicGutter>
    </>;

    function renderContent() {
        if (inviteViaQrCode) return qrCodeForm;
        if (inviteViaEmail) return emailForm;
        if (inviteViaShare) {
            if (canShare) return shareForm;
            else return copiedForm;
        }
        return initialForm;
    }

    return <>
        <PageAndContent toolbarChildren={<IonText>Subaccount Invite</IonText>}>
            <IonGrid>
                {renderContent()}
            </IonGrid>
        </PageAndContent>
    </>;
}
import { ApiGet, ApiPost } from '..';

export interface ApiKey {
    id: string;
    key: string;
}

export const GetApiKeysAsync = async (): Promise<ApiKey | null> => {
    var response = await ApiGet('GetApiKeys');
    return response.status === 200 ? response.data : null;
};

export const RecycleApiKeysAsync = async () => {
    var response = await ApiPost("RecycleApiKeys");
    return String(response.status).startsWith('2');
};
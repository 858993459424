import { IonContent, IonGrid, IonRow, IonCol, IonImg, IonText, IonButton } from "@ionic/react"

export interface StandingOrderQrCodeSuccessProps {
    onClose: Function,
}

export default function StandingOrderQrCodeModalSuccess(props: StandingOrderQrCodeSuccessProps) {
    return <>
        <IonContent>
            <div className="toolbarBackground"></div>

            <IonGrid style={{marginTop:'170px'}}>
                <IonRow className="imageRow">
                    <IonCol sizeXs="4" sizeSm="4.5" sizeMd="5" sizeLg="5" sizeXl="5"> </IonCol>
                    <IonCol sizeXs="4" sizeSm="3" sizeMd="2" sizeLg="2" sizeXl="2"> <IonImg src='assets/img/successTick.png' /> </IonCol>
                    <IonCol sizeXs="4" sizeSm="4.5" sizeMd="5" sizeLg="5" sizeXl="5"> </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="2"> </IonCol>
                    <IonCol size="8">
                        <IonText><h5 className="fullPageNotificationTitle">Standing Order Created Successfully</h5></IonText>
                    </IonCol >
                    <IonCol size="2"> </IonCol>
                </IonRow>

                <IonRow></IonRow>

                <IonRow className="modalButtonRow">
                    <IonCol sizeXs="1" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2"> </IonCol>
                    <IonCol sizeXs="10" sizeSm="8" sizeMd="8" sizeLg="8" sizeXl="8" >
                        <IonButton onClick={() => props.onClose()} color={'secondary'}>Next &#187;</IonButton>
                    </IonCol>
                    <IonCol sizeXs="1" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2"></IonCol>
                </IonRow>

                <IonRow></IonRow>
            </IonGrid>

        </IonContent>
    </>
}
import React, { useState } from 'react';
import { IonList, IonItem, IonLabel } from '@ionic/react';
import './ItemListControl.css';

interface Item {
  key: string;
  value: string;
}

interface ItemListControlProps {
  items: Item[];
  onItemSelected: (selectedValue: string) => void;
  disabledItems?: string[]; // Optional array of string values to disable specific items
}


const ItemListControl = ({ items, onItemSelected, disabledItems = [] }: ItemListControlProps) => {
  const [selectedItem, setSelectedItem] = useState('');

  const handleItemClick = (item: Item) => {
    if (!disabledItems.includes(item.key)) {
      setSelectedItem(item.key);
      onItemSelected(item.key);
    }
  };

  return (
     <IonList>
      {items.map((item) => (
        <IonItem
          key={item.key}
          className={selectedItem === item.key ? 'list-item selected' : 'list-item'}
          onClick={() => handleItemClick(item)}
          disabled={disabledItems.includes(item.key)}>
          <IonLabel>{item.value}</IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
};

export default ItemListControl;
import { GetOnboardUrl, Onboarding, OnboardStatus } from '../../../lib/data/OnboardingData';
import PageSpinner from '../../controls/shared/PageSpinner';
import { useEffect, useRef } from 'react';

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function IdentityCheckStep(props: Props) {
    const lockApiCalls = useRef(false);
    const applicant = 
        props.onboarding.businessDetails.beneficiaries.find(b => b.isApplicant === true) ||
        props.onboarding.businessDetails.directors.find(d => d.isApplicant === true);

    useEffect(() => {
        async function getOnboardUrl() {
            try {
                const url = await GetOnboardUrl();
                window.location.href = url;
            } catch (error) {
                console.error(error);
            } finally {
                lockApiCalls.current = false;
            }
        }

        if (lockApiCalls.current) return;
        lockApiCalls.current = true;

        getOnboardUrl();
    }, []);
    
    if (!applicant) {
        props.onNext();
        return <PageSpinner />;
    }

    const onboardStatus = applicant.onboardSession?.status;
    if (onboardStatus === OnboardStatus.Waiting ||
        onboardStatus === OnboardStatus.Complete) {
        props.onNext();
        return <PageSpinner />;
    }

    return <PageSpinner />; // might be rendered very briefly?
}
import { IonButton, IonContent, IonPage, useIonRouter } from '@ionic/react';
import { RowWithDynamicGutter } from '../controls/shared/Grid';
import './ContactSupport.css';
import { PageAndContent } from '../controls/nav/PageControls';

export default function ContactSupport() {
    const router = useIonRouter();

    function goBackButton() {
        router.goBack()
    }

    return <>
        <PageAndContent>
            <RowWithDynamicGutter>
                <h1 className='contactSupportTitleRow'>Contact Support</h1>
                <h5 className='contactSupportTextRow'>For any enquiries, please contact us at customerservice@trustisttransfer.com</h5>
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <IonButton
                    shape="round"
                    color="primary"
                    size="default"
                    onClick={goBackButton}>Go Back &#187;</IonButton>
            </RowWithDynamicGutter>
        </PageAndContent>
    </>;
}
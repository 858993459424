import { IonCol, IonGrid, IonRow } from "@ionic/react";

interface GridProps {
    children: React.ReactNode,
    rowClassName?: string,
    colClassName?: string,
    gridClassName?: string,
}

export function Grid(props: GridProps) {
    return <IonGrid className={props.gridClassName}>{props.children}</IonGrid>
}

export function Row(props: GridProps) {
    return <IonRow className={props.rowClassName}>{props.children}</IonRow>;
}

export function RowWithCol(props: GridProps) {
    return <IonRow className={props.rowClassName}>
        <IonCol className={props.colClassName}>{props.children}</IonCol>
    </IonRow>;
}

export function RowWithPaddingColumns(props: GridProps) {
    return <Row rowClassName={props.rowClassName}><IonCol />{props.children}<IonCol /></Row>;
}

export function RowWithMiddleColumn(props: GridProps) {
    return <RowWithPaddingColumns rowClassName={props.rowClassName}>
        <IonCol className={props.colClassName} sizeXl='8' sizeLg='8' sizeMd='8' sizeSm='8' sizeXs='10'>{props.children}</IonCol>
    </RowWithPaddingColumns>;
}

export function GridWithRowAndMiddleColumn(props: GridProps) {
    return <Grid gridClassName={props.gridClassName}>
        <RowWithMiddleColumn rowClassName={props.rowClassName}>{props.children}</RowWithMiddleColumn>
    </Grid>;
}

export function RowWithDynamicGutter(props: GridProps) {
    return <IonRow className={props.rowClassName }>
        <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5' className={props.colClassName }></IonCol>
        <IonCol sizeXs='10' sizeSm='8' sizeMd='6' sizeLg='4' sizeXl='3' className={props.colClassName}>
            {props.children}
        </IonCol>
        <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5' className={props.colClassName}></IonCol>
    </IonRow>;
}

export function RowWithSmallGutters(props:GridProps) {
    return <IonRow className={props.rowClassName }>
    <IonCol sizeXs='0.5' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
    <IonCol sizeXs='11' sizeSm='8' sizeMd='6' sizeLg='4' sizeXl='3' className={props.colClassName}>
        {props.children}
    </IonCol>
    <IonCol sizeXs='0.5' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
</IonRow>;
}

export function StaticGutterRow(props: GridProps) {
    return <IonRow className={props.rowClassName}>
        <IonCol size='1' className={props.colClassName}></IonCol>
        <IonCol size='10' className={props.colClassName}>
            {props.children}
        </IonCol>
        <IonCol size='1' className={props.colClassName}></IonCol>
    </IonRow>;
}
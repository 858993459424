import { IonButton, IonIcon, IonInput } from "@ionic/react";
import { remove } from 'ionicons/icons';
import { TextButton } from "../../controls/shared/Buttons";
import _ from "lodash";

interface AmountInputProps {
    readOnly: boolean;
    amounts: number[];
    setAmounts: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function AmountInput({ readOnly, amounts, setAmounts }: AmountInputProps) {
    function handleAmountChange(index: number, newValue: string) {
        const existingAmounts = [...amounts];
        let parsedVal = parseFloat(newValue);

        if (isNaN(parsedVal) || parsedVal < 0) parsedVal = 0;

        if (existingAmounts[index] != _.ceil(parsedVal, 2)) {
            existingAmounts[index] = _.ceil(parsedVal, 2);
            setAmounts(existingAmounts);
        }
    };

    function addAmount() {
        setAmounts([...amounts, 0]);
    };

    function removeAmount(index: number) {
        const newAmounts = amounts.filter((_, idx) => idx !== index);
        setAmounts(newAmounts);
    };

    return (
        <div>
            {amounts.map((amount, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }} className="ion-margin-bottom">
                    <IonInput
                        readonly={readOnly === true}
                        value={amount}
                        onIonChange={(e) => handleAmountChange(index, e.detail.value!)}>
                    </IonInput>
                    {readOnly === false && (
                        <IonButton class="circularDeleteButton" style={{ width: "36px" }} onClick={() => removeAmount(index)}>
                            <IonIcon slot="icon-only" icon={remove}></IonIcon>
                        </IonButton>
                    )}
                </div>
            ))}

            {readOnly === false && <TextButton size={'small'} onClick={addAmount}>{amounts.length < 1 ? 'Add Amount' : 'Add Another'}</TextButton>}

        </div>
    );
};
import { IonGrid, IonImg } from "@ionic/react";
import { Onboarding } from "../../../lib";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import { PageAndContent } from "../../controls/nav/PageControls";

type Props = {
    onboarding: Onboarding,
};

export default function ManualStep(props: Props) {
    const form = <>
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo ion-margin-bottom" src='./assets/img/logo.png' />
                    <h1 className='mainHeader'>We’ll Be in Touch</h1>
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <p style={{ fontSize: "15px" }} className="ion-text-center">A member of the team will be in touch to assist in onboarding.</p>
                </RowWithDynamicGutter>
            </IonGrid>
        </PageAndContent>
    </>;

    return form;
}
import { IonPage, IonContent, IonCheckbox, IonButton, IonItem, IonLabel, IonText, IonImg } from "@ionic/react";
import { StepProps } from "./OnboardingMenu";
import { useRef, useState } from "react";
import { SetOnboardingPaymentTypes, OnboardingPaymentTypes } from "../../../lib";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";

export default function PaymentTypesStep(props: StepProps) {
    const payments = props.onboarding.payments;
    const [cardsEnabled, setCardsEnabled] = useState(payments.paymentTypes.includes(OnboardingPaymentTypes.Cards));
    const [cardsConfirmed, setCardsConfirmed] = useState(false);
    const [amexEnabled, setAmexEnabled] = useState(payments.paymentTypes.includes(OnboardingPaymentTypes.Amex));
    const lockApiCalls = useRef(false);

    async function confirmCards() {
        if (lockApiCalls.current) return;
        lockApiCalls.current = true;

        try {
            const paymentTypes = [];
            if (cardsEnabled) paymentTypes.push(OnboardingPaymentTypes.Cards);
            await SetOnboardingPaymentTypes(paymentTypes, !cardsEnabled);
            if (!cardsEnabled) props.onNext();
            else setCardsConfirmed(true);
        } catch (error) {
            console.error(error);
        } finally {
            lockApiCalls.current = false;
        }
    }

    async function confirmAmex() {
        if (lockApiCalls.current) return;
        lockApiCalls.current = true;

        try {
            const paymentTypes = ["cards"]; // must want cards by being here
            if (amexEnabled) paymentTypes.push(OnboardingPaymentTypes.Amex);
            await SetOnboardingPaymentTypes(paymentTypes, true);
            props.onNext();
        } catch (error) {
            console.error(error);
        } finally {
            lockApiCalls.current = false;
        }
    }

    const cardsStep = (
        <div className="ion-padding">
            <RowWithDynamicGutter>
                <IonImg className="smallLogo" src="./assets/img/logo.png" />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <h1 className="ion-margin-bottom sectionTitle">Card payments Setup</h1>
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>

                <p className="onboardingSubText">Card payments are subject to transaction fees of 0.99%</p>
                <p className="onboardingSubText">
                    Once you have reached a payout amount of £1 (after fees) your payout will be suspended until our card payment provider, Ryft, have performed their KYC checks on you and your business.
                </p>
                <p className="onboardingSubText">We may contact you for more information.</p>

                <ul className="onboardingSubText">
                    <li>Tick the box to agree to the T&Cs and enable card payments</li>
                    <li>Leave it unticked to skip this for now - you can set set this up later.</li>
                </ul>


                <IonItem lines="none">
                    <IonCheckbox slot="start" checked={cardsEnabled} onIonChange={e => setCardsEnabled(e.detail.checked)} />
                    <p className="onboardingSubText">Tick this box to indicate that you agree to the <a href="https://ryftpay.com/terms-conditions" className="ion-text-primary" target="_blank" rel="noreferrer"><b>Ryft Terms of Service</b></a></p>
                </IonItem>

                <div className="ion-margin-top ion-text-center">
                    <IonButton color="tertiary" expand="block" onClick={() => confirmCards()}>Confirm</IonButton>
                    <IonButton expand="block" onClick={() => props.onNext()}>Cancel</IonButton>
                </div>
            </RowWithDynamicGutter>
        </div>
    );

    const amexStep = (
        <div className="ion-padding">
            <RowWithDynamicGutter>
                <IonImg className="smallLogo" src="./assets/img/logo.png" />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <h1 className="sectionTitle ion-margin-bottom">American Express Setup</h1>
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>

                <p className="onboardingSubText">American Express payments are subject to transaction fees of 2.99%</p>
                <p className="onboardingSubText">
                    Once you have reached a payout amount of £1 (after fees) your payout will be suspended until our card payment, Ryft, have performed their KYC checks on you and your business.
                </p>
                <p className="onboardingSubText">We may contact you for more information.</p>

                <ul className="onboardingSubText">
                    <li>Tick the box to agree to the T&Cs and enable American Express payments</li>
                    <li>Leave it unticked to skip this for now - you can set set this up later.</li>
                </ul>


                <IonItem lines="none">
                    <IonCheckbox slot="start" checked={amexEnabled} onIonChange={e => setAmexEnabled(e.detail.checked)} />
                    <p className="onboardingSubText">Tick this box to indicate that you agree to the <a href="https://ryftpay.com/terms-conditions" className="ion-text-primary" target="_blank" rel="noreferrer"><b>Ryft Terms of Service</b></a></p>
                </IonItem>

                <div className="ion-margin-top ion-text-center">
                    <IonButton color="tertiary" expand="block" onClick={() => confirmAmex()}>Confirm</IonButton>
                    <IonButton expand="block" onClick={() => props.onNext()}>Cancel</IonButton>
                </div>
            </RowWithDynamicGutter>
        </div>
    );

    return (
        <IonPage>
            <IonContent>
                {cardsConfirmed ? amexStep : cardsStep}
            </IonContent>
        </IonPage>
    );
}

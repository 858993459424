import { IonToast } from '@ionic/react';
import { close, checkmark } from 'ionicons/icons';
import './Toast.css';

export interface ToastParams {
    isOpen?: boolean,
    onDidDismiss?: () => void,
    type?: ToastTypes,
    message?: string,
    duration?: number,
};

export enum ToastTypes {
    Success,
    Error,
    // Pending,
};

const defaultType = ToastTypes.Success;

export const InactiveToast: ToastParams = {
    isOpen: false,
    message: "",
    type: defaultType,
};

const cssClass = (type: ToastTypes): string => {
    switch (type) {
        case ToastTypes.Error: return "errorToast";
        // case ToastTypes.Pending: return "";
        default: return "successToast";
    };
};

const defaultMessage = (type: ToastTypes): string => {
    switch (type) {
        case ToastTypes.Error: return "An error occurred";
        // case ToastTypes.Pending: return "Pending";
        default: return "Success";
    };
};

const toastIcon = (type: ToastTypes): string => {
    switch (type) {
        case ToastTypes.Error: return close;
        // case ToastTypes.Pending: return ;
        default: return checkmark;
    };
};

export default function Toaster(props: ToastParams) {
    return <>
        <IonToast
            isOpen={props.isOpen ?? false}
            onDidDismiss={props.onDidDismiss}
            message={props.message ?? defaultMessage(props.type ?? defaultType)}
            duration={props.duration ?? 3000}
            position={'middle'}
            cssClass={`toast ${cssClass(props.type ?? defaultType)}`}
            buttons={[
                {
                    side: 'start',
                    icon: toastIcon(props.type ?? defaultType) as any,
                }
            ]}
        />
    </>;
}
import { ApiPost, ApiGet } from "../util/Api";
import { BankAccount } from "./MerchantData";

export const RequestAccountInformationServicesUrlAsync = async (
    redirectPath: string,
    includeTransactions: boolean = false) : Promise<string | null> => {
    var response = await ApiGet(`RequestAccountInformationServicesUrl?redirectUrl=${encodeURIComponent(redirectPath)}&includeTransactions=${includeTransactions}`);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
};

export const StoreAccountInformationTokenAsync = async (
    requestId: string,
    includeTransactions: boolean = false): Promise<boolean> => {
    var response = await ApiPost(`StoreAccountInformationToken?requestId=${requestId}&includeTransactions=${includeTransactions}`);
    return response.status === 200;
};

export const ListBankAccountsFromRequestIdAsync = async (requestId: string): Promise<BankAccount[]> => {
    var response = await ApiGet(`ListBankAccountsFromRequestId?requestId=${requestId}`);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
};

export const ReauthoriseMonitoring = async () => {
    var response = await ApiPost('ReauthoriseMonitoring', {});
    return response.status === 200;
}
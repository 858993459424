export const msalConfig = {
    auth: {
        clientId: "bbc1499c-b940-4530-baf5-c2c015823185",
        authority: "https://login.trustist.com/trustistclient.onmicrosoft.com/B2C_1_CustomSignUpSignIn",
        knownAuthorities: ["login.trustist.com"], // array of URIs that are known to be valid "trustistclient.b2clogin.com",
        redirectUri: "",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    authorities: {
        editProfile: "",
    }
}

export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_susi",
        forgotPassword: "b2c_1_reset",
        editProfile: "B2C_1_profile1"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://login.trustist.com/trustistclient.onmicrosoft.com/b2c_1_susi",
        },
        forgotPassword: {
            authority: "https://login.trustist.com/trustistclient.onmicrosoft.com/b2c_1_reset",
        },
        editProfile: {
            authority: "https://login.trustist.com/trustistclient.onmicrosoft.com/B2C_1_profile1"
        }
    },
    authorityDomain: "login.trustist.com",
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        "https://trustistclient.onmicrosoft.com/trustist-transfer-api/Api.Full",
        "openid",
        "profile",
        "offline_access"]
}

export const tokenRequest = {
    scopes: ["https://trustistclient.onmicrosoft.com/trustist-transfer-api/Api.Full"]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
};
import { IonContent, IonPage, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useUptimia } from "../../../contexts/UptimiaContext";

interface PageAndContentProps {
    children: React.ReactNode;
    toolbarChildren?: React.ReactNode;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    footerChildren?: React.ReactNode;
}

export function PageAndContent({ children, toolbarChildren, contentClassName, contentStyle, footerChildren }: PageAndContentProps) {
    const location = useLocation();
    const { reportPageViewToUptimia } = useUptimia();
    const navigationTiming = useRef({
        navigationStart: 0,
        hasReported: false,
        pathname: ''
    });

    // Reset timing on new navigation
    useEffect(() => {
        if (navigationTiming.current.pathname !== location.pathname) {
            navigationTiming.current = {
                navigationStart: performance.now(),
                hasReported: false,
                pathname: location.pathname
            };
        }
    }, [location.pathname]);

    useIonViewDidEnter(() => {
        const timing = navigationTiming.current;
        if (!timing.hasReported && timing.pathname === location.pathname) {
            const endTime = performance.now();
            const elapsed = endTime - timing.navigationStart;  // Actual navigation time in milliseconds
            
            // Split the total time into segments
            const timings = {
                domtm: Math.round(elapsed * 0.2),    // 20% of navigation time
                rendertm: Math.round(elapsed * 0.3),  // 30% of navigation time
                connecttm: Math.round(elapsed * 0.2), // 20% of navigation time
                waittm: Math.round(elapsed * 0.2),    // 20% of navigation time
                transfertm: Math.round(elapsed * 0.1)  // 10% of navigation time
            };

            reportPageViewToUptimia(location.pathname, timings);
            timing.hasReported = true;
        }
    });

    return (
        <IonPage>
            {toolbarChildren && <IonToolbar className='titleBar'>
                {toolbarChildren}
            </IonToolbar>}
            <IonContent className={contentClassName} style={contentStyle}>
                {children}
            </IonContent>
            {footerChildren}
        </IonPage>
    );
}

export function Page({ children }: { children: React.ReactNode }) {
    return <IonPage>{children}</IonPage>;
}

import { ApiGet, ApiPost } from '..';
import { ApiResult } from '../../pages/controls/shared/AutoComplete';

export type Company = {
    company_number: string,
    company_name: string,
};

export const CompaniesHouseSearch = async (company_name: string) : Promise<Company[]> => {
    var response = await ApiGet("CompaniesHouseSearch?query=" + encodeURIComponent(company_name));

    if (response.status === 200) {
        return response.data;
    }

    return [];
}

export const CompaniesHouseSelect = async (company_number: string): Promise<boolean> => {
    var response = await ApiPost("CompaniesHouseSelection", { companyNumber: company_number });

    return response.status === 200;
}

export const CompaniesHouseSicCodeSearch = async (query: string): Promise<ApiResult[]> => {
    var response = await ApiGet("CompaniesHouseSicCodeSearch?query=" + query);
    return response.status === 200 ? response.data : [];
}
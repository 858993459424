import { IonCol, IonContent, IonGrid, IonImg, IonModal, IonRouterLink, IonRow, IonText } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { RetrieveStandingOrder, StandingOrderRequest, StandingOrderStatus } from "../../lib/data/StandingOrders";
import { useInterval } from "../controls/hooks/useInterval";
import PageSpinner from "../controls/shared/PageSpinner";
import StandingOrderQrCodeModalFailure from "./StandingOrderQrCodeModalFailure";
import StandingOrderQrCodeModalSuccess from "./StandingOrderQrCodeModalSuccess";
import ShareUrlButton from "../controls/shared/ShareUrlButton";

export interface StandingOrderQrCodeProps {
    standingOrderRequest: StandingOrderRequest,
    onComplete: Function,
}

export default function StandingOrderQrCode(props: StandingOrderQrCodeProps) {
    const [standingOrderStatus, setStandingOrderStatus] = useState(StandingOrderStatus.Unknown);
    const isRetrieving = useRef(true);

    useInterval(() => {
        if (isRetrieving.current === true && props.standingOrderRequest) {
            getStandingOrder();
        }
    }, 3000)

    // check the status once at the start to get things going
    useEffect(() => {
        if (props.standingOrderRequest) {
            getStandingOrder();
            isRetrieving.current = true;
        }
    }, [props.standingOrderRequest]);

    async function getStandingOrder() {
        try {
            var response = await RetrieveStandingOrder(props.standingOrderRequest.id!);

            // updates may have been disabled between this call starting and now
            if (isRetrieving.current === false) return;

            setStandingOrderStatus(response.status);

            if (response.status === StandingOrderStatus.Active) {
                isRetrieving.current = false;
            }
        }
        catch (e) {
            console.log("Error polling for standing order updates: " + e);
        }
    }

    function closeModal(e: any) {
        if (e) e.preventDefault();
        props.onComplete();
    };
    
    const startDate = props.standingOrderRequest.payload.startDate
    const endDate = props.standingOrderRequest.payload.endDate

    // Check if start date is defined and not null
    const formattedStartDate = startDate ? new Date(startDate).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    }) : 'Invalid Start Date';

    // Check if end date is defined and not null
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    }) : 'Invalid End Date';

    function convertFrequencyToString(frequency: number) {
        switch (frequency) {
            case 1:
                return "Daily";
            case 2:
                return "Weekly";
            case 3:
                return "TwiceWeekly";
            case 4:
                return "Monthly";
            case 5:
                return "TwiceMonthly";
            case 6:
                return "Quarterly";
            case 7:
                return "SemiAnnually";
            case 8:
                return "Annually";
            default:
                return "Unknown Frequency";
        }
    }

    function paymentStatusScreen() {
        switch (standingOrderStatus) {
            case StandingOrderStatus.Active:
                return <StandingOrderQrCodeModalSuccess onClose={closeModal} />

            case StandingOrderStatus.Failed:
                return <StandingOrderQrCodeModalFailure onClose={closeModal} />

            case StandingOrderStatus.Unknown:
                return <PageSpinner></PageSpinner>

            default:
                return <IonContent>

                    <IonRow className='modalTitleRow'>
                    </IonRow>

                    <IonGrid>
                        <IonRow>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                            <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6" class='ion-text-center'>
                                <IonText color='primary'>
                                    <h1 style={{ fontWeight: 500 }} className='scanBelowText'>Standing Order Amount</h1>
                                </IonText>
                            </IonCol>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class='ion-text-center'>
                                <IonText color='primary' className='ion-text-wrap'>
                                    <h2 className='takePaymentValue'><span className='takePaymentPound'>£</span>{props.standingOrderRequest.payload.amount?.toFixed(2)}</h2>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2"> </IonCol>
                            <IonCol sizeXs="10" sizeSm="8" sizeMd="8" sizeLg="8" sizeXl="8">
                                <IonRow style={{ color: '#003466' }}>
                                    <IonText style={{ fontSize: '20px', fontWeight: 'bold' }}>Payment Details</IonText>
                                </IonRow>
                                <IonRow>
                                    <IonText style={{ fontSize: '16px', fontWeight: 500, color: '#003466' }}>First Payment Date: <span style={{ fontWeight: 'bold' }}>{formattedStartDate}</span></IonText>
                                </IonRow>
                                <IonRow>
                                    <IonText style={{ fontSize: '16px', fontWeight: 500, color: '#003466' }}>Number Of Payments: <span style={{ fontWeight: 'bold' }}>{props.standingOrderRequest.payload.numberOfPayments === (0 || null )? "Ongoing" : props.standingOrderRequest.payload.numberOfPayments}</span></IonText>
                                </IonRow>
                                <IonRow>
                                    <IonText style={{ fontSize: '16px', fontWeight: 500, color: '#003466' }}>
                                        Frequency: <span style={{ fontWeight: 'bold' }}>{props.standingOrderRequest.payload.frequency !== undefined
                                            ? convertFrequencyToString(props.standingOrderRequest.payload.frequency)
                                            : "Unknown Frequency"}</span>
                                    </IonText>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="2" sizeLg="2" sizeXl="2" > </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="2" sizeSm="3" sizeMd="3" sizeLg="3" sizeXl="3"> </IonCol>
                            <IonCol sizeXs="8" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonImg src={`/api/qrcode?logo=tt1&content=${props.standingOrderRequest.payLink}`} />
                            </IonCol>
                            <IonCol sizeXs="2" sizeSm="3" sizeMd="3" sizeLg="3" sizeXl="3" > </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                            <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6">
                                <ShareUrlButton url={props.standingOrderRequest.payLink!} buttonText="Share PayLink »" />
                            </IonCol >
                            <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <IonCol class="ion-text-center" >
                                <IonRouterLink
                                    className='closeModal'
                                    style={{ textDecoration: "underline" }}
                                    onClick={closeModal}>
                                    <><b>Back</b></>
                                </IonRouterLink>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
        }
    }

    return <>
        <IonModal className="standingOrderModal" isOpen={true} backdropDismiss={false}>
            {paymentStatusScreen()}
        </IonModal>
    </>;
}
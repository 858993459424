import { IonGrid, IonRow, IonCol, IonImg, IonSpinner, IonPage, IonContent } from "@ionic/react";

export default function LoadingPage() {
    return <>
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol></IonCol>
                        <IonCol sizeXl='3' sizeLg='4' sizeMd='6' sizeSm='8' sizeXs='10'>
                            <IonImg src='assets/img/logo.png' /> <br></br>
                            <IonSpinner className='spin' />
                        </IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;
}
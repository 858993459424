import { IonIcon, IonRadio, IonRadioGroup } from "@ionic/react";
import { VatRate } from "../../../lib";

import { arrowDownCircle, arrowUpCircle } from 'ionicons/icons';
import { useEffect, useState } from "react";

export type VatRateSelectionControlProps = {
    readonly?: boolean,
    objectsAffectedText: string,
    expandable: boolean,
    vatRates: VatRate[],
    selectedVatRate: VatRate | undefined,
    onVatRateSelected: (vatRate: VatRate) => void,
}

export default function VatRateSelectionControl(props: VatRateSelectionControlProps) {
    const [showVatRates, setShowVatRates] = useState(false);
    const [selectedRate, setSelectedRate] = useState<number | undefined>();

    useEffect(() => {
        if (props.selectedVatRate) {
            const index = props.vatRates.findIndex(rate => rate.name === props.selectedVatRate?.name);
            setSelectedRate(index + 1);
        }
    }, [props.selectedVatRate, props.vatRates]);

    const handleVatRateChange = (value: number) => {
        setSelectedRate(value);
        props.onVatRateSelected(props.vatRates[value - 1]);
    };

    return <>
        <div className="greyPanel" style={{ paddingBottom: "2px", paddingTop: "10px" }}>
            <div className="panelTitle">
                <span>VAT Options</span>
                {props.expandable && <IonIcon
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    src={showVatRates ? arrowUpCircle : arrowDownCircle}
                    onClick={() => setShowVatRates(prev => !prev)} />}
            </div>

            {(!props.expandable || showVatRates) && <>
                <p>
                    It is your responsibility to ensure that the correct VAT rate is chosen.
                    If the VAT option you’d like to use does not appear here, please edit the options in your settings.
                </p>

                <IonRadioGroup 
                    onIonChange={e => handleVatRateChange(e.detail.value)}
                    value={selectedRate}>
                    {props.vatRates.map((rate, index) =>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "15px", fontSize: "14px" }} key={index + 1}>
                            <IonRadio value={index + 1} key={index + 1} disabled={props.readonly || false}></IonRadio>
                            &nbsp;
                            {rate.name} - {rate.rate}%
                            <br />
                        </div>
                    )}
                </IonRadioGroup>

                <p style={{ fontSize: "12px" }}>
                    This will only set the VAT rate for {props.objectsAffectedText}.
                    To change the default VAT settings for your account, click the ‘settings’ icon in the bottom right of your screen.
                </p>
            </>
            }
        </div>
    </>;
}
import { createRef, useEffect, useState } from "react";
import { ButtonRef, SubmitButton } from "../controls/shared/Buttons";
import { SelectInput } from "../controls/shared/Inputs";
import DatePickerRange from "../controls/shared/DatePickerRange";
import { DownloadTransactionsAsync } from "../../lib";

enum DateRangeType {
    AllTime = 1,
    Today,
    Yesterday,
    ThisWeek,
    LastWeek,
    ThisMonth,
    LastMonth,
    Last7Days,
    Last30Days,
    Custom,
};

export default function DownloadDataControl() {
    const [dateRangeType, setDateRangeType] = useState<DateRangeType>();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const buttonRef = createRef<ButtonRef>();

    useEffect(() => {
        if (!dateRangeType) return;

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        switch (dateRangeType) {
            case DateRangeType.Today:
                setStartDate(new Date(today));
                setEndDate(new Date(today));
                break;
            case DateRangeType.Yesterday:
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);
                setStartDate(yesterday);
                setEndDate(yesterday);
                break;
            case DateRangeType.ThisWeek:
                const weekStart = new Date(today);
                weekStart.setDate(weekStart.getDate() - weekStart.getDay());
                setStartDate(weekStart);
                setEndDate(new Date(today));
                break;
            case DateRangeType.LastWeek:
                const lastWeekStart = new Date(today);
                lastWeekStart.setDate(lastWeekStart.getDate() - lastWeekStart.getDay() - 7);
                const lastWeekEnd = new Date(lastWeekStart);
                lastWeekEnd.setDate(lastWeekEnd.getDate() + 6);
                setStartDate(lastWeekStart);
                setEndDate(lastWeekEnd);
                break;
            case DateRangeType.ThisMonth:
                const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
                setStartDate(monthStart);
                setEndDate(new Date(today));
                break;
            case DateRangeType.LastMonth:
                const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
                setStartDate(lastMonthStart);
                setEndDate(lastMonthEnd);
                break;
            case DateRangeType.Last7Days:
                const last7DaysStart = new Date(today);
                last7DaysStart.setDate(today.getDate() - 6);
                setStartDate(last7DaysStart);
                setEndDate(new Date(today));
                break;
            case DateRangeType.Last30Days:
                const last30DaysStart = new Date(today);
                last30DaysStart.setDate(today.getDate() - 29);
                setStartDate(last30DaysStart);
                setEndDate(new Date(today));
                break;
            case DateRangeType.AllTime:
            case DateRangeType.Custom:
                setStartDate(null);
                setEndDate(null);
                break;
        }
    }, [dateRangeType]);

    async function downloadDataAsync() {
        try {
            await DownloadTransactionsAsync(startDate, endDate);
        }
        finally {
            setDateRangeType(undefined);
            setStartDate(null);
            setEndDate(null);
            buttonRef.current?.stopSpinning();
        }
    }

    return <>
        <div style={{ color: '#003466' }}>
            <b>Transaction Data</b>
            <p>
                Download a CSV file of your past transaction data, select the date range required and click download.
            </p>
        </div>
        <SelectInput
            placeholder="Date Range"
            options={[
                { value: DateRangeType.AllTime, label: "All Time" },
                { value: DateRangeType.Today, label: "Today" },
                { value: DateRangeType.Yesterday, label: "Yesterday" },
                { value: DateRangeType.ThisWeek, label: "This Week" },
                { value: DateRangeType.LastWeek, label: "Last Week" },
                { value: DateRangeType.ThisMonth, label: "This Month" },
                { value: DateRangeType.LastMonth, label: "Last Month" },
                { value: DateRangeType.Last7Days, label: "Last 7 Days" },
                { value: DateRangeType.Last30Days, label: "Last 30 Days" },
                { value: DateRangeType.Custom, label: "Custom" },
            ]}
            selected={dateRangeType}
            onChange={val => setDateRangeType(val)} />

        {dateRangeType === DateRangeType.Custom && <>
            <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                onChange={(start, end) => {
                    setStartDate(start);
                    setEndDate(end);
                }} />
        </>}
        <SubmitButton
            disabled={!(startDate && endDate) && dateRangeType !== DateRangeType.AllTime}
            text="Download"
            onClick={downloadDataAsync}
            ref={buttonRef} />
    </>;
}
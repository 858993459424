import { IonButton, IonGrid, IonImg, IonInput } from "@ionic/react";
import { useRef, useState } from "react";
import { Onboarding } from "../../../lib";
import { CompaniesHouseSearch, CompaniesHouseSelect, Company } from "../../../lib/data/CompaniesHouse";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import "./CompaniesHouseSearch.css";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import ItemListControl from "../../controls/shared/ItemListControl";
import { PageAndContent } from "../../controls/nav/PageControls";

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function CompaniesHouseStep(props: Props) {
    const [compHouseSearchData, setCompHouseSearchData] = useState('');
    const [compHouseResultData, setCompHouseResultData] = useState<Company[]>([]);
    const [selectedCompanyNumber, setSelectedCompanyNumber] = useState<string>();
    const [errorMessage, setErrorMessage] = useState('');
    const searchButtonRef = useRef<ButtonRef>(null);

    async function handleCompHouseSearch() {
        const response = await CompaniesHouseSearch(compHouseSearchData);

        if (compHouseSearchData === '') {
            setErrorMessage('Please Enter a Business Name');
            searchButtonRef.current?.stopSpinning(true);
        }
        else if (response.length < 1) {
            setErrorMessage('No results, please check any spellings');
            searchButtonRef.current?.stopSpinning(true);
        }
        else {
            setErrorMessage('');
            setCompHouseResultData(response);
        }
    };

    async function onClickNotRegistered(e: any) {
        setCompHouseResultData([]);
        props.onNext();
    };

    async function handleSelectedCompany() {
        await CompaniesHouseSelect(selectedCompanyNumber!);
        setCompHouseResultData([]);
        props.onNext();
    }

    var companySelect = <>
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo" src='./assets/img/logo.png' />
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <div className="sectionTitle">
                        <b>Pick Your Business</b>
                    </div>
                    <div className="listExtension">
                        <ItemListControl
                            items={compHouseResultData.map((company: Company) => ({ key: company.company_number, value: company.company_name }))}
                            onItemSelected={companyNumber => setSelectedCompanyNumber(companyNumber)}
                        /></div>
                    <div className='bottomButtonContainer'>
                        <div className='bottomButtonRow'>
                            <SubmitButton
                                class="submit-button"
                                disabled={!selectedCompanyNumber}
                                onClick={handleSelectedCompany}
                                text="Next »"
                            />
                        </div>
                    </div>
                    <IonButton
                        className="notRegisteredButton"
                        fill="clear"
                        onClick={onClickNotRegistered}
                    >
                        I Can't see my Business »
                    </IonButton>
                </RowWithDynamicGutter>
            </IonGrid >
        </PageAndContent>
    </>;

    const companyList = <>
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo" src='./assets/img/logo.png' />
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <h1 className="sectionTitle"><b>Business Registration Details</b></h1>
                    <div style={{ color: '#003466', marginBottom: '30px' }}>
                        <p className="onboardingSubText">
                            Please provide us with information about your business, this will help us verify your business faster
                        </p>
                    </div>
                    <IonInput
                        placeholder="Business Name"
                        onIonChange={(e) => setCompHouseSearchData(e.target.value as string)}
                        value={compHouseSearchData}
                        className='questionInput'
                        type="text" />
                    <div className="errorMessageDiv">
                        <div className="error-message" hidden={errorMessage === ''}>{errorMessage}</div>
                    </div>
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <div className='bottomButtonContainer'>
                        <div className='bottomButtonRow'>
                            <SubmitButton
                                ref={searchButtonRef}
                                onClick={() => handleCompHouseSearch()}
                                text="Search »" />
                        </div>
                    </div>
                </RowWithDynamicGutter>
            </IonGrid >
        </PageAndContent>
    </>;
    return compHouseResultData.length > 0 ? companySelect : companyList;
}
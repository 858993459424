import { IonInput } from "@ionic/react";
import { useRef, useState } from "react";
import { Address, OnboardSession, OnboardStatus, SendOnboardLink } from "../../../lib";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";

type OnboardPerson = {
    id: string,
    name: string,
    address: Address,
    shareLink: string,
    onboardSession?: OnboardSession,
}

type OnboardPersonProps = {
    person: OnboardPerson,
};

export function OnboardLinkShare(props: OnboardPersonProps) {
    const [person, setPerson] = useState<OnboardPerson>(props.person);
    const [emailAddress, setEmailAddress] = useState<string>();
    const buttonRef = useRef<ButtonRef>(null);

    async function onClickShare(person: OnboardPerson, buttonRef: ButtonRef) {
        if (!emailAddress) return;

        try {
            await SendOnboardLink(person.id, emailAddress);

            setPerson({ ...person, shareLink: "dummy" });
        } catch (error) {
            console.error('Error sharing link:', error);
        }

        buttonRef.stopSpinning();
    }

    function InvitedPersonStatus() {
        if (person.onboardSession?.status === OnboardStatus.Complete) {
            return <span style={{ fontSize: "15px", color: '#58C800' }}>Complete</span>
        }

        if (person.onboardSession?.status === OnboardStatus.Failed && person.onboardSession!.attemptCount >= 3) {
            return <span style={{ fontSize: "15px", color: '#FF0404' }}>Failed</span>
        }

        if (person.shareLink) {
            return <span style={{ fontSize: "15px", color: '#FFA700' }}>Invited</span>
        }
    }
    function isValidEmail(): boolean {
        // Check if the email is not empty
        if (!emailAddress) {
            return false;
        }

        // Regular expression for validating email
        const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        // Test the email against the regular expression
        return emailRegex.test(emailAddress);
    }

    return <RowWithDynamicGutter key={"person_" + person.id}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="onboardingMainText">
                <b>{person.name}</b><br />
                {person.address.addressLine1}, {person.address.city}
            </div>

            <div>
                {InvitedPersonStatus()}
            </div>
        </div>

        <p className="inputLabel">Email Address</p>
        <IonInput type="email" onIonChange={(e) => setEmailAddress(e.detail.value || undefined)}></IonInput>

        <SubmitButton
            disabled={!isValidEmail()}
            colour={(person.shareLink ? "secondary" : "primary")}
            class={person.shareLink ? "blueButtonClassName" : "normalButtonClassName"}
            ref={buttonRef}
            onClick={() => onClickShare(person, buttonRef.current!)}
            text={(person.shareLink ? "Re-" : "") + "Share Invite Link »"} />
    </RowWithDynamicGutter>;
}
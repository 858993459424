import { IPublicClientApplication } from "@azure/msal-browser";
import { IonButton } from "@ionic/react";
import { useEffect, useState } from "react";
import { GetMsalInstance } from "../../lib";

interface ControlProps {
  className?: string;
}

const Control: React.FC<ControlProps> = ({ className }) => {
  var [msalInstance, setMsalInstance] = useState<IPublicClientApplication>();

  useEffect(() => {
    GetMsalInstance().then(instance => setMsalInstance(instance));
  });

  return <>

    <IonButton
      className={` ${className || ""}`}
      onClick={() => msalInstance?.logout()}>
      Log Out &#187;
    </IonButton>

  </>
};

export default Control;
import { IonButton, IonCol, IonLoading, IonRow } from "@ionic/react";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { GlobalToastContext } from "./GlobalToastProvider";
import { RowWithDynamicGutter } from "./Grid";

export type FileUploadProps = {
    title?: string,
    buttonLabel?: string,
    status?: string,
    fileName?: string,
    rowWithDynamicGutter?: boolean,
    subTitle?: string,

    onFileChange: (file: File) => Promise<string>, // returns filename
    onFileSelect: (fileName: string) => {},
};

export const FileUploadStatus = {
    PENDING: "pending",
    UPLOADING: "uploading",
    REJECTED: "rejected",
    ACCEPTED: "accepted",
};

export default function FileUploadControl(props: FileUploadProps) {
    // some defaults
    props = {
        rowWithDynamicGutter: true,
        ...props,
    }

    const [status, setStatus] = useState(props.status);
    const fileInput = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string | undefined>(props.fileName);
    const globalToastContext = useContext(GlobalToastContext);

    function cleanFileName(fullPath: string) {
        let parts = fullPath.split('/');
        return parts[parts.length - 1];
    }

    function onFileChange(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target?.files) {
            globalToastContext.error("No file selected");
            return;
        }

        const uploadedFile = e.target.files[0];

        if (uploadedFile.size > 5000000) {
            globalToastContext.error("File size cannot be larger than 5mb");
        }
        else {
            setStatus(FileUploadStatus.UPLOADING);

            props.onFileChange(uploadedFile)
                .then((result) => {
                    if (result > '') {
                        setStatus(FileUploadStatus.ACCEPTED);
                        setFileName(result);
                    } else {
                        setStatus(FileUploadStatus.REJECTED);
                    }
                })
                .catch(() => {
                    setStatus(FileUploadStatus.REJECTED);
                });
        }

        e.target.value = "";
    }

    const mainControl = <>
        <div>
            <input
                ref={fileInput}
                hidden
                type='file'
                //accept='image/png, image/jpg, '
                onChange={onFileChange} />

            {fileName && fileName > '' && <>
                <IonRow>
                    <IonCol size="7" >
                        <div id="fileDisplayButton"
                            onClick={() => props.onFileSelect(fileName)}>{cleanFileName(fileName)}
                        </div>
                    </IonCol>
                    <IonCol size="5" style={{ textAlign: 'right' }}>
                        <IonButton
                            color="secondary"
                            size="small"
                            id="changeFileButton"
                            hidden={status === FileUploadStatus.UPLOADING}
                            onClick={() => {
                                if (!fileInput.current) return;
                                fileInput.current.click();
                            }}>
                            Change &#187;
                        </IonButton>
                    </IonCol>
                </IonRow>
                
            </>}

            {(fileName || '') === '' && <>
            
                <IonButton
                    style={{marginTop: '-10px'}}
                    color="primary"
                    size="small"
                    className="width130Button"
                    hidden={status === FileUploadStatus.UPLOADING}
                    onClick={() => {
                        if (!fileInput.current) return;
                        fileInput.current.click();
                    }}>
                    {props.buttonLabel || "Attach File »"}
                </IonButton>

                
            </>}

            <IonLoading
                isOpen={status === FileUploadStatus.UPLOADING}
                message={'Please wait...'} />
        </div>
        

    </>;

    const row = <>
        <RowWithDynamicGutter>
            {props.title &&
                <div className="onboardingMainText ion-margin-bottom">
                    <b>{props.title}</b>
                </div>}

            {props.subTitle &&
                <p className="subtitle">
                    Please attach a high quality image
                </p>}

            {mainControl}
        </RowWithDynamicGutter>
    </>;

    return props.title ? row : mainControl;
}
import { IonText } from '@ionic/react';
import './PaymentHistory.css';
import TransactionList from './payments/TransactionList';
import { PageAndContent } from './controls/nav/PageControls';

export default function PaymentHistory() {
    return <>
        <PageAndContent toolbarChildren={<IonText>Transactions</IonText>}>
            <TransactionList></TransactionList>
        </PageAndContent>
    </>;
}
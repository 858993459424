import { IonGrid, IonImg } from "@ionic/react";
import { useRef } from "react";
import { KycMode, Onboarding } from "../../../lib";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import { OnboardLinkShare } from "../controls/OnboardLinkSharing";
import { PageAndContent } from "../../controls/nav/PageControls";

export type OwnerVerificationProps = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function OwnerVerificationStep(props: OwnerVerificationProps) {
    const completeButtonRef = useRef<ButtonRef>(null);
    const people = props.onboarding.businessDetails.beneficiaries.filter(p => (p.isApplicant || false) === false);
    const directors = props.onboarding.businessDetails.directors.filter(d => (d.isApplicant || false) === false);

    function onClickComplete() {
        props.onNext();
    }

    const inviteForm = <>
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className='smallLogo' src='./assets/img/logo.png' />
                    <h1 className='sectionTitle'>Invite For Verification</h1>
                    <p className="onboardingSubText">
                        {props.onboarding.kycMode === KycMode.BusinessDirector ?
                            "We need one of the directors listed in the company documents to verify their identity"
                            :
                            "We need all people listed in the company documents who directly own or control more than 25% of your business to verify their identity"
                        }
                    </p>
                </RowWithDynamicGutter>

                {props.onboarding.kycMode === KycMode.BusinessBeneficiaries && people.map(person => <OnboardLinkShare
                    key={person.id}
                    person={{
                        id: person.id,
                        name: `${person.firstName} ${person.lastName}`,
                        address: person.address,
                        shareLink: person.shareLink,
                        onboardSession: person.onboardSession,
                    }} />)}

                {props.onboarding.kycMode === KycMode.BusinessDirector &&
                    directors.map(director => <OnboardLinkShare
                        key={director.id}
                        person={{
                            id: director.id,
                            name: `${director.name}`,
                            address: director.address,
                            shareLink: director.shareLink,
                            onboardSession: director.onboardSession,
                        }} />)}

                <RowWithDynamicGutter>
                    <SubmitButton
                        class="ion-margin-top"
                        ref={completeButtonRef}
                        onClick={onClickComplete}
                        text="Continue »" />
                </RowWithDynamicGutter>
            </IonGrid>
        </PageAndContent>
    </>;

    return inviteForm;
}
import { InputChangeEventDetail, IonInput, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import { TextFieldTypes } from "@ionic/core";
import "./Buttons.css";

export interface TextInputProps {
    disabled?: boolean | undefined;
    placeholder?: string,
    value?: string,
    onChange?: (value?: string) => void,
    type?: TextFieldTypes,
    label?: string,
    maxlength?: number,
    subLabel?: string,
    readOnly?: boolean | undefined,
}

export function TextInput(props: TextInputProps) {
    return <>
        {props.label &&
            <IonText className='inputLabel'>{props.label}</IonText>}

        {props.subLabel &&
        <div style={{marginTop:'-30px'}}>
            <IonText className="subLabel"><br/>{props.subLabel}</IonText>
        </div>}    

        {props.readOnly && props.readOnly === true
            ? <IonInput
            disabled={true}
            type={props.type}
            placeholder={props.placeholder}
            className="disabledInput"
            onIonChange={(val: CustomEvent<InputChangeEventDetail>) => props.onChange && props.onChange(val?.detail?.value || undefined)}
            value={props.value}
            maxlength={props.maxlength}
             />
            : <IonInput
                disabled={props.disabled}
                type={props.type}
                placeholder={props.placeholder}
                className="input"
                onIonChange={(val: CustomEvent<InputChangeEventDetail>) => props.onChange && props.onChange(val?.detail?.value || undefined)}
                value={props.value}
                maxlength={props.maxlength} />}
        
    </>;
}

interface SelectOption<T> {
    value: T;
    label: string;
}

export interface SelectInputProps<T> {
    placeholder?: string,
    label?: string,
    onChange: (value?: T) => void,
    options: SelectOption<T>[],
    selected: T,
    readOnly?: boolean | undefined,
    disabled?: boolean | undefined,
}

export function SelectInput<T>(props: SelectInputProps<T>) {
    return <>
        {props.label &&
            <IonText className='inputLabel'>{props.label}</IonText>}

        {props.readOnly && props.readOnly === true
            ? <IonSelect className='select disabledInput' placeholder={props.placeholder}
            value={props.selected}
            interface="alert"
            disabled={true}
            onIonChange={(e) => props.onChange(e.detail.value)}>
            {props.options.map((option, index) => (
                <IonSelectOption key={index} value={option.value as any}>
                    {option.label}
                </IonSelectOption>
            ))}
        </IonSelect>
            : <IonSelect className='select input' placeholder={props.placeholder}
            value={props.selected}
            interface="alert"
            onIonChange={(e) => props.onChange(e.detail.value)}>
            {props.options.map((option, index) => (
                <IonSelectOption key={index} value={option.value as any}>
                    {option.label}
                </IonSelectOption>
            ))}
        </IonSelect>}
    </>;
}
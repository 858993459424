import { IonButton, IonContent, IonGrid, IonIcon, IonItem, IonItemGroup, IonLabel, IonModal, IonNote, IonText, IonToolbar } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { ListStandingOrderTransactions, StandingOrder, StandingOrderTransaction, StandingOrderTransactionType } from '../../lib/data/StandingOrders';
import { FormatDate } from '../../lib/util/Dates';
import { StaticGutterRow } from '../controls/shared/Grid';

export interface StandingOrderParams {
    item: StandingOrder,
    onClosed(): void
}

export default function StandingOrderModal(props: StandingOrderParams) {
    const item = props.item;
    const [transactions, setTransactions] = useState<StandingOrderTransaction[]>([]);
    const [transactionContinuation, setTransactionContinuation] = useState<string | undefined>();
    const lockApi = useRef(false);

    useEffect(() => {
        if (lockApi.current === true) return;
        lockApi.current = true;

        ListStandingOrderTransactions(item.id, transactionContinuation).then((response) => {
            setTransactions(response.data);
            setTransactionContinuation(response.continuation);
            lockApi.current = false;
        });
    }, [item]);

    function loadMore() {
        if (lockApi.current === true) return;
        lockApi.current = true;

        ListStandingOrderTransactions(item.id, transactionContinuation).then((response) => {
            setTransactions(previous => [...previous, ...response.data]);
            setTransactionContinuation(response.continuation);
            lockApi.current = false;
        });
    }

    function convertFrequencyToString(frequency: number) {
        switch (frequency) {
            case 1:
                return "Daily";
            case 2:
                return "Weekly";
            case 3:
                return "TwiceWeekly";
            case 4:
                return "Monthly";
            case 5:
                return "TwiceMonthly";
            case 6:
                return "Quarterly";
            case 7:
                return "SemiAnnually";
            case 8:
                return "Annually";
            default:
                return "Unknown Frequency";
        }
    }

    return <>
        <IonModal className='modal' backdropDismiss={false} isOpen={true}>
            <IonContent>
                <IonToolbar className='titleBar'>
                    <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
                        <IonButton onClick={() => props.onClosed()} className="circularButton">
                            <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
                        </IonButton>
                    </div>

                    <IonText style={{ color: '#003466', textAlign: 'center', }}>
                        <h1>
                            <b>{item.payload.payerName}</b>
                        </h1>
                    </IonText>
                </IonToolbar>

                <IonGrid>
                    <IonItemGroup>
                        {item.payload.payerBusinessName && item.payload.payerBusinessName > '' &&
                            <StaticGutterRow>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <p>
                                        Business Name: <span style={{ fontWeight: '500' }}>{item.payload.payerBusinessName}</span>
                                    </p>
                                </IonText>
                            </StaticGutterRow>}

                        <StaticGutterRow rowClassName={item.payload.payerBusinessName ? 'border-top' : ''}>
                            <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                <p className='modalText'>
                                    <span style={{ fontWeight: 'bold' }}>Payment Amount:</span>
                                    {' '}
                                    <span style={{ fontWeight: 500 }}>£{item.payload.amount}</span>
                                </p>
                            </IonText>
                        </StaticGutterRow>

                        <StaticGutterRow rowClassName='border-top'>
                            <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                <p className='modalText'>
                                    <span style={{ fontWeight: 'bold' }}>Frequency:</span>
                                    {' '}
                                    {item.payload.frequency !== undefined
                                        ? <span style={{ fontWeight: 500 }}>{convertFrequencyToString(item.payload.frequency)}</span>
                                        : "Unknown Frequency"
                                    }
                                </p>
                            </IonText>
                        </StaticGutterRow>

                        <StaticGutterRow rowClassName='border-top'>
                            <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                <p className='modalText'>
                                    {item.payload.startDate ? (
                                        <>
                                            <span style={{ fontWeight: 'bold' }}>First Payment Date:</span>
                                            {' '}
                                            <span style={{ fontWeight: '500' }}>{new Date(item.payload.startDate).toLocaleDateString("en-GB")}</span>
                                        </>
                                    ) : (
                                        <><span style={{ fontWeight: 'bold' }}>First Payment Date:</span><span style={{ fontWeight: '500' }}> N/A</span></>
                                    )}
                                </p>
                            </IonText>
                        </StaticGutterRow>
                        <StaticGutterRow rowClassName={item.payload.description && item.payload.description > '' ? 'border-top' : 'border-top border-bottom' }>
                            <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                <p className='modalText'>
                                    {item.payload.numberOfPayments ? (
                                        <>
                                            <span style={{ fontWeight: 'bold' }}>Number of Payments:</span>
                                            {' '}
                                            <span style={{ fontWeight: '500' }}>{item.payload.numberOfPayments}</span>
                                        </>
                                    ) : (
                                        <><span style={{ fontWeight: 'bold' }}>Number of Payments:</span><span style={{ fontWeight: '500' }}> Ongoing</span></>
                                    )}
                                </p>
                            </IonText>
                        </StaticGutterRow>

                        {item.payload.description && item.payload.description > '' &&
                            <StaticGutterRow rowClassName='border-top border-bottom'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='modalText'><b>Description:</b> <span style={{ fontWeight: '500' }}>{item.payload.description}</span></h5>
                                </IonText>
                            </StaticGutterRow>
                        }


                        <h2 style={{ color: '#003466', textAlign: 'left', fontWeight: 'bold', marginLeft: '15px' }}>Transactions</h2>


                        {transactions && transactions.map((transaction, index) => <>
                            <IonItem id={`payment_${transaction.id}`} key={transaction.id}>
                                <IonLabel>
                                    <h2 style={{ color: transaction.type === StandingOrderTransactionType.Unmonitored ? '#666' : '#003466' }}>
                                        <b>{FormatDate(transaction.paymentDate)}</b>
                                    </h2>
                                    <p style={{ color: transaction.type === StandingOrderTransactionType.Unmonitored ? '#666' : '#003466' }}>
                                        ID - {transaction.id}
                                    </p>
                                    {transaction.type === StandingOrderTransactionType.Verified && <p className='successGreen'> Monitored - Found</p>}
                                    {transaction.type === StandingOrderTransactionType.Unverified && <p style={{ color: '#FFA700' }}> Monitored - Not Found</p>}
                                    {transaction.type === StandingOrderTransactionType.Unmonitored && <p> Not Monitored</p>}
                                </IonLabel>
                                <IonNote style={{ color: transaction.type === StandingOrderTransactionType.Unmonitored ? '#666' : '#003466' }} slot='end'>
                                    <h4> £{item.payload?.amount?.toFixed(2)}</h4>
                                </IonNote>
                            </IonItem>
                        </>)}
                    </IonItemGroup>

                    {transactionContinuation && transactionContinuation > '' &&
                        <StaticGutterRow>
                            <IonButton onClick={() => loadMore()}>Load more...</IonButton>
                        </StaticGutterRow>}
                </IonGrid>

            </IonContent>
        </IonModal>
    </>;
}
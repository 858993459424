import { useState, useEffect } from 'react';

type Setter<T> = React.Dispatch<React.SetStateAction<T>>;

export default function useLocalStorageState<T>(key: string, defaultValue: T | undefined): [T | undefined, Setter<T | undefined>] {
    const [state, setState] = useState<T | undefined>(() => {
        const storedValue = window.localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    useEffect(() => {
        if (typeof state === 'undefined') {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, JSON.stringify(state));
        }
    }, [key, state]);

    return [state, setState];
}
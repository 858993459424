import { InputChangeEventDetail, IonButton, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonList, IonNote, IonPage, IonText, IonToolbar, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { Merchant } from "../../lib";
import { AccountContext } from "../../contexts/AccountContext";
import { RowWithDynamicGutter } from "../controls/shared/Grid";
import PageSpinner from "../controls/shared/PageSpinner";
import './SubMerchant.css';
import { PageAndContent } from "../controls/nav/PageControls";

export default function SubMerchantList() {
    var accountContext = useContext(AccountContext);
    var [merchant, setMerchant] = useState<Merchant | undefined | null>();
    var [filter, setFilter] = useState("");
    const router = useIonRouter();

    useEffect(() => accountContext?.refresh(), []);

    useEffect(() => {
        setMerchant(accountContext?.merchant);
    }, [accountContext?.merchant])

    function viewMerchant(merchantName: string) {
        router.push(`/tabs/submerchants/${merchantName}`);
    }

    return <>{merchant
        ? <>
            <PageAndContent toolbarChildren={<IonText class='titleBarText'>Subaccounts</IonText>}>
                <IonGrid fixed className="tabPageBottomMargin">
                    <RowWithDynamicGutter>
                        <IonButton
                            id="addSubMerchantButton"
                            color="primary"
                            onClick={() => router.push("/tabs/newsubmerchant")}>Add New &#187;
                        </IonButton>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonInput
                            placeholder='Filter by Name/Location Name'
                            className="input"
                            onIonChange={(val: CustomEvent<InputChangeEventDetail>) => setFilter(val.detail.value!)}
                            value={filter}>
                        </IonInput>
                    </RowWithDynamicGutter>
                    <IonList>
                        {merchant.submerchants?.filter(s => filter === "" || s?.name?.toLowerCase().indexOf(filter.toLowerCase()) > -1).map((submerchant, index) => {
                            return (
                                <IonItem className='selectableItem' id={`submerchant_${submerchant.name}`} key={submerchant.name} onClick={() => viewMerchant(submerchant.name)}>
                                    <IonLabel className="listItemLabel">
                                        <h2 style={{ fontWeight: 'bold', color: '#003466' }}>{submerchant.name}</h2>
                                    </IonLabel>

                                    <IonLabel className="listItemLabel" slot='end'>
                                        <h2 style={{ color: `#${submerchant.status === "Active" ? "17DA36" : "ffa700"}` }}>{submerchant.status}</h2>
                                    </IonLabel>
                                </IonItem>
                            );
                        })}
                    </IonList>
                </IonGrid>
            </PageAndContent>
        </>
        : <PageSpinner></PageSpinner>}
    </>;
}
import { format } from 'date-fns';
import { ApiGet, ApiPost } from "..";

export interface StandingOrderRequest {
    id: string,
    clientDateCreated: Date,
    payload: StandingOrderPayload,
    payLink: string,
}

export interface StandingOrder {
    id: string,
    created: Date,
    clientDateCreated: Date,
    payload: StandingOrderPayload,
    status: StandingOrderStatus,
}

export interface StandingOrderTransaction {
    id: string,
    paymentDate: Date,
    type: StandingOrderTransactionType,
}

export interface StandingOrderTransactionList {
    data: StandingOrderTransaction[],
    continuation: string,
}

export interface StandingOrderPreset {
    id: string,
    name: string,
    payload: StandingOrderPayload,
}

export interface StandingOrderPayload {
    frequency?: StandingOrderFrequency,
    startDate?: Date,
    endDate?: Date,
    amount?: number,
    payerName?: string,
    payerBusinessName?: string,
    description?: string,
    numberOfPayments?: number,
}

export enum StandingOrderFrequency {
    Daily = 1,
    Weekly,
    TwiceWeekly,
    Monthly,
    TwiceMonthly,
    Quarterly,
    SemiAnnually,
    Annually
}

export enum StandingOrderStatus {
    Unknown = 0, // dummy/empty/should not be used
    Pending = 10, // created but no further action has taken place
    Started = 20, // payer has begun the process
    Processing = 30, // payer has returned from Token
    Failed = 50, // failed to create the standing order
    Abandoned = 70, // the process never completed (not yet used but should be)
    Active = 100, // the standing order is processing payments
    MissedPayment = 150, // we have become aware a scheduled payment was not made
    Cancelled = 170, // we have become aware the standing order will never complete
    Completed = 200, // the standing order successfully completed all its payments
}

export enum StandingOrderTransactionType {
    Verified = 10,
    Unverified = 20,
    Unmonitored = 30,
}

export const CreateStandingOrder = async (payload: StandingOrderPayload): Promise<StandingOrderRequest> => {
    var url = 'CreateStandingOrder?';
    var response = await ApiPost(url, {
        payload,
        clientDateCreated: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
    });

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const ListStandingOrders = async (
    continuation?: string,
    searchText?: string,
    frequency?: StandingOrderFrequency) => {
    var url = 'ListStandingOrders?';
    if (frequency) url += `frequency=${frequency}&`;
    if (searchText) url += `searchText=${searchText}&`;
    if (continuation) url += `continuation=${encodeURIComponent(continuation)}&`;

    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const RetrieveStandingOrder = async (standingOrderId: string): Promise<StandingOrder> => {
    var response = await ApiGet(`RetrieveStandingOrder?standingOrderId=${standingOrderId}`);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const ListStandingOrderTransactions = async (standingOrderId: string, continuation?: string): Promise<StandingOrderTransactionList> => {
    var url = `ListStandingOrderTransactions?standingOrderId=${standingOrderId}`;
    if (continuation) url += `&continuation=${encodeURIComponent(continuation)}`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const CreateStandingOrderPreset = async (name: string, payload: StandingOrderPayload): Promise<StandingOrderPreset> => {
    var url = 'CreateStandingOrderPreset?';
    var response = await ApiPost(url, { name, payload });

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const UpdateStandingOrderPreset = async (name: string, payload: StandingOrderPayload, id : string): Promise<StandingOrderPreset> => {
    var url = 'UpdateStandingOrderPreset?';
    var response = await ApiPost(url, { name, payload, id });

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const ListStandingOrderPresets = async (
    continuation?: string,
    searchText?: string,
    frequency?: StandingOrderFrequency) => {

    var url = 'ListStandingOrderPresets?';
    if (frequency) url += `frequency=${frequency}&`;
    if (searchText) url += `searchText=${searchText}&`;
    if (continuation) url += `continuation=${continuation}&`;

    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const DeleteStandingOrderPreset = async (id: string): Promise<StandingOrderPreset> => {
    var url = `DeleteStandingOrderPreset?id=${id}`;
    var response = await ApiPost(url, { });

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

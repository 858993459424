import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useContext, useEffect } from 'react';

import '@ionic/react/css/core.css';
import './App.css';
import './theme/variables.css';

import { EventType, IPublicClientApplication } from '@azure/msal-browser';
import { Route } from 'react-router';
import { appConfig, GetMsalInstance } from './lib';
import AccountProvider, { AccountConsumer, AccountContext, AccountContextType } from './contexts/AccountContext';
import TabNav from './pages/controls/nav/TabNav';
import GlobalToastProvider from './pages/controls/shared/GlobalToastProvider';
import Loading from './pages/Loading';
import Onboarding from './pages/onboarding/Onboarding';
import Invite from './pages/submerchants/SubMerchantInvite';
import ReauthoriseMonitoringPage from './pages/monitoring/ReauthoriseMonitoring';
import { UptimiaProvider, setupUptimiaErrorHandling } from './contexts/UptimiaContext';
import { UptimiaErrorBoundary } from './contexts/UptimiaErrorBoundary';
import RequestAmexPage from './pages/settings/cards/RequestAmexPage';
import RequestCardsPage from './pages/settings/cards/RequestCardsPage';

// Set up error handling before anything else
setupUptimiaErrorHandling();
setupIonicReact();

function App() {
    const accountContext = useContext(AccountContext);

    useEffect(() => {
        let callbackId: any;
        let msalInstance: IPublicClientApplication;

        async function addAccountCallback() {
            msalInstance = await GetMsalInstance();

            callbackId = msalInstance.addEventCallback(async (message: any) => {
                if (message.eventType === EventType.HANDLE_REDIRECT_END) {
                    if (accountContext) accountContext.refresh();
                }
            });
        }

        const result = addAccountCallback();

        return function () {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId);
            }
        }
    }, []);

    function hideStagingBanner() {
        if (appConfig?.isStaging === true || appConfig?.isDevelopment === true || appConfig?.isSandbox === true) {
            return 'stagingBanner'
        }
        else {
            return 'ion-hide'
        }
    }

    function environmentBannerName() {
        if (appConfig?.isSandbox === true) {
            return 'Sandbox'
        }
        else if (appConfig?.isStaging === true) {
            return 'Staging'
        }
        else if (appConfig?.isDevelopment === true) {
            return 'Development'
        }
        else {
            return ''
        }
    }

    function renderContent(ctx: AccountContextType) {
        switch (ctx.account?.status) {

            case 'ACTIVE':
                return <TabNav />;
            case 'NEW':
            case 'ONBOARDING':
                return <Onboarding />;

            default: return <></>
        };
    }

    function mainNav() {
        return <AccountConsumer>
            {ctx => ctx?.account
                ? renderContent(ctx)
                : <Loading />
            }
        </AccountConsumer>;
    }

    return (
        <UptimiaErrorBoundary>
            <UptimiaProvider>
                <AccountProvider>
                    <IonApp>
                        <GlobalToastProvider>
                            <IonReactRouter>
                                <IonRouterOutlet id="main">
                                    <Route path="/tabs" component={mainNav} />
                                    <Route path="/" component={mainNav} exact />
                                    <Route path="/reauthoriseMonitoring" component={ReauthoriseMonitoringPage} exact />
                                    <Route path="/Invite/:InviteCode" component={Invite} exact />
                                    <Route path="/cards" component={RequestCardsPage} exact />
                                    <Route path="/amex" component={RequestAmexPage} exact />
                                </IonRouterOutlet>
                            </IonReactRouter>
                        </GlobalToastProvider>
                        <div className={hideStagingBanner()}>
                            {environmentBannerName()}
                        </div>
                    </IonApp>
                </AccountProvider>
            </UptimiaProvider>
        </UptimiaErrorBoundary>
    );
};

export default App;

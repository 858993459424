import { InteractionRequiredAuthError, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, tokenRequest } from "../../authConfig";
import { appConfig } from "./Config";

let msalInstance: IPublicClientApplication;
let semaphore = false;

export async function GetMsalInstance() : Promise<IPublicClientApplication> {
    if (!msalInstance && !semaphore) {
        semaphore = true;
        msalInstance = await BuildMsalInstance();
    }

    return msalInstance;
};

export async function BuildMsalInstance(altAuthority?: string) {
    const msal = msalConfig;
    if (!appConfig?.auth?.redirectUrl) {
        throw new Error("App config is missing auth.redirectUrl");
    }
    msal.auth.redirectUri = appConfig.auth.redirectUrl;
    if (altAuthority) msal.auth.authority = altAuthority;
    const newInstance = new PublicClientApplication(msal);
    return newInstance;
}

export async function GetAccessToken() {
    var msalInstance = await GetMsalInstance();
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
    const request = { ...tokenRequest, account };

    try {
        var token = await msalInstance.acquireTokenSilent(request);
        return token?.accessToken;
    } 
    catch (error){
        if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(request).catch(e => {
                console.error(e);
            });
        }
    }
}
import { IonButton, IonInput } from '@ionic/react';
import { useState } from "react";
import { Merchant, SetRedirectUrlAsync } from '../../lib';
import "./RedirectUrl.css";
import { set } from 'lodash';

type Props = {
    merchant: Merchant,
    refreshMerchant: Function,
};

export default function Control(props: Props) {
    const maxCharectorAllowed = 22;
    const merchant = props.merchant;
    const [redirectButtonText, setRedirectButtonText] = useState(merchant.redirectButtonText);
    const [redirectURL, setRedirectURL] = useState(merchant.redirectURL);
    const [counter, setCounter] = useState(redirectButtonText && redirectButtonText.length);
    const [isEditMode, setIsEditMode] = useState(false);

    function onRedirectButtonTextChanged(val: CustomEvent) {
        setRedirectButtonText(val.detail.value);
        setCounter(val.detail.value && val.detail.value.length);
    };

    async function saveSetPayerServices() {
        try {
            await SetRedirectUrlAsync(redirectButtonText, redirectURL);
        } finally {
            setIsEditMode(false);
            props.refreshMerchant();
        }
    }

    function onClickCancel() {
        setIsEditMode(false);
        setRedirectButtonText(merchant.redirectButtonText);
        setRedirectURL(merchant.redirectURL);
    }

    return <>
        <div className="panelTitle">
            <h2>Redirect URL</h2>
            <IonButton
                shape="round"
                color="secondary"
                size="small"
                hidden={isEditMode}
                className="settingsButtons" onClick={() => setIsEditMode(true)}>Edit &#187;</IonButton>
            <IonButton
                shape="round"
                color="primary"
                size="small"
                hidden={!isEditMode}
                className="settingsButtons" onClick={saveSetPayerServices}>Save &#187;</IonButton>
        </div>

        <div className='ion-margin-top ion-margin-bottom' style={{ color: '#a0a0a0' }}> Change the location of the redirect URL on your receipt screen. </div>

        <IonInput
            className='ion-margin-bottom'
            placeholder="e.g. https://www.trustistrewards.com"
            value={redirectURL}
            readonly={isEditMode === false}
            onIonChange={(e) => { setRedirectURL(e.detail.value!) }}>
        </IonInput>

        <h2 className="panelTitle">Redirect Button Text</h2>

        <div className='ion-margin-top ion-margin-bottom' style={{ color: '#a0a0a0' }}>
            Change the text of the redirect URL button on your receipt screen.
            &#40;{maxCharectorAllowed} character limit&#41; {counter}
        </div>

        <IonInput placeholder="e.g. Next &#187;"
            value={redirectButtonText}
            maxlength={22}
            readonly={isEditMode === false}
            onIonChange={onRedirectButtonTextChanged}>
        </IonInput>

        {isEditMode &&
            <div className='redirectCancelCol'>
                <br />
                <IonButton
                    shape="round"
                    color="secondary"
                    size="small"
                    className="redirectCancelButton redirectCancelCol" onClick={onClickCancel}>Cancel &#187;</IonButton>
            </div>}
    </>
}
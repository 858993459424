import { IonButton } from "@ionic/react";
import { forwardRef, Ref, useImperativeHandle, useState } from "react";
import Spinner from "./Spinner";
import "./Buttons.css";

export interface ButtonProps {
    text: string,
    onClick: Function,
    disabled?: boolean,
    colour?: string,
    class?: string,
}

export interface ButtonRef {
    stopSpinning: Function,
}

export interface TextButtonProps {
    onClick?: () => void;
    href?: string;
    target?: string;
    rel?: string;
    color?: string;
    size?: any;
    disabled?: boolean;

}

export const TextButton: React.FC<TextButtonProps> = ({
    children,
    onClick,
    href,
    target,
    rel,
    color = 'primary',
    size = 'default',
    disabled = false,

}
) => {
    return (
        <IonButton
            onClick={onClick}
            href={href}
            target={target}
            rel={rel}
            color={color}
            size={size}
            disabled={disabled}
            expand="block"
            fill="clear"
            shape="round"
            class="ion-no-padding text-button">
            {children}
        </IonButton>
    );
};

export const SubmitButton = forwardRef((props: ButtonProps, ref: Ref<ButtonRef>) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useImperativeHandle(ref, () => ({ stopSpinning }));

    function stopSpinning() {
        setShowSpinner(false);
    }

    function click(e: any) {
        setShowSpinner(true);
        props.onClick();
    }

    return <IonButton
        color={props.colour || "secondary"}
        size="default"
        disabled={props.disabled || showSpinner}
        className={props.class + " submitButton"}
        onClick={click}>{showSpinner ? <Spinner /> : props.text}</IonButton>;
});

export const SubmitButtonSquare = forwardRef((props: ButtonProps, ref: Ref<ButtonRef>) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useImperativeHandle(ref, () => ({ stopSpinning }));

    function stopSpinning() {
        setShowSpinner(false);
    }

    function click(e: any) {
        setShowSpinner(true);
        props.onClick();
    }

    return <IonButton
        color={props.colour || "secondary"}
        size="default"
        disabled={props.disabled || showSpinner}
        className={props.class + " submitButtonSquare"}
        onClick={click}>{showSpinner ? <Spinner /> : props.text}</IonButton>;
});



export const EmailButton: React.FC = () => {
    const sendEmail = () => {
      const emailAddress = 'customerservice@trustisttransfer.com';
      const subject = 'TrustistTransfer ID Verification';
      const body = '';
  
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
  
      window.location.href = mailtoLink;
    };
  
    return (
      <IonButton  color={"secondary"}  onClick={sendEmail}>
        Email Us &#187;
      </IonButton>
    );
  };

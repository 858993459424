import axios from "axios";
import { GetAccessToken } from "./Auth";
import { reportUptimiaEvent } from '../../contexts/UptimiaContext';

const axiosInstance = axios.create({
    baseURL: '/api',
});

const defaultConfig = {
    responseType: 'json',
    validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404 || status === 409 || status === 424,
};

export const jsonConfig = {
    responseType: 'json',
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async (originalResponse) => {
        const headers = originalResponse?.headers;
        if (!headers) return;

        const accessToken = await GetAccessToken();
        if (accessToken) {
            headers["Authorization"] = "Bearer " + accessToken;
        }

        if (!headers["Content-Type"]) {
            headers["Content-Type"] = "application/json";
        }

        return originalResponse;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Add to your axios interceptors
axiosInstance.interceptors.response.use(
    (response) => {
        // Report only POST requests
        if (response.config.method?.toLowerCase() === 'post') {
            reportUptimiaEvent(response.config.url || '');
        }
        return response;
    },
    (error) => {
        // Report only failed POST requests
        if (error.config?.method?.toLowerCase() === 'post') {
            reportUptimiaEvent(error.config.url);
        }
        return Promise.reject(error);
    }
);

export const ApiPost = async (url: string, data: any = null, config: any = defaultConfig) => {
    const uptimiaTimer = reportUptimiaEvent(url);
    try {
        const response = await axiosInstance.post(url, data, config);
        uptimiaTimer.success();
        return response;
    } catch (error) {
        uptimiaTimer.success(); // Still report timing even on error
        throw error;
    }
};

export const ApiDelete = (url: string, data: any = null, config: any = defaultConfig) => axiosInstance.delete(url, data);

export const ApiPut = (url: string, data: any, config?: any) => {
    if (config && config?.headers) {
        return axiosInstance.put(url, data,
            {
                headers: config.headers
            }
        );
    }

    return axiosInstance.put(url, data);
}

export const ApiGet = (url: string, config: any = defaultConfig) => axiosInstance.get(url, config);

export async function ApiFileDownload(url: string) : Promise<boolean> {
    await axiosInstance({
        url,
        method: 'GET',
        responseType: 'blob', // Important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/);
            if (fileNameMatch?.length === 2)
            {
                fileName = fileNameMatch[1];
            }
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });

    return true;
}

export type ApiResponse<T = void> = {
    success: boolean;
    status: number;
    message: string;
    data?: T;
}

const toApiResponse = <T,>(response: { 
    status: number; 
    data?: { 
        message?: string; 
        data?: T; 
    }; 
}): ApiResponse<T> => ({
    success: response.status >= 200 && response.status < 300,
    status: response.status,
    message: response.data?.message ||'',
    data: response.data?.data
});

export const ApiGetResponse = <T = void>(url: string, config: any = defaultConfig): Promise<ApiResponse<T>> => 
    axiosInstance.get(url, config).then(toApiResponse<T>);

export const ApiPostResponse = <T = void>(url: string, data: any = null, config: any = defaultConfig): Promise<ApiResponse<T>> => 
    axiosInstance.post(url, data, config).then(toApiResponse<T>);

export const ApiPutResponse = <T = void>(url: string, data: any, config?: any): Promise<ApiResponse<T>> => {
    if (config && config?.headers) {
        return axiosInstance.put(url, data, { headers: config.headers }).then(toApiResponse<T>);
    }
    return axiosInstance.put(url, data).then(toApiResponse<T>);
};
import { format } from 'date-fns';
import { ApiGetBankHolidays } from './Config';

export const FormatDate = (date: Date , dateFormat: string = "d MMM - hh:mmaaa") :string => {
    return format(new Date(date),dateFormat );
}

export function AddUtcDays(date: Date, days: number): Date {
    const result = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    result.setUTCDate(result.getUTCDate() + days);
    return result;
}

export function GetTodayDate() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}

const BH_CACHE_KEY = "bankHolidays";
const BH_CACHE_EXPIRATION_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Function to retrieve bank holidays
export async function GetBankHolidays(): Promise<Date[]> {
    const now = new Date().getTime();
    const cachedData = localStorage.getItem(BH_CACHE_KEY);

    if (cachedData) {
        const { expiration, data } = JSON.parse(cachedData);

        // Check if the cached data is still valid
        if (now < expiration) {
            return data.map((dateString: string) => new Date(dateString));
        }
    }

    // Fetch new data if there's no valid cache
    const freshData = await ApiGetBankHolidays();
    const expirationTime = now + BH_CACHE_EXPIRATION_MS;

    // Cache the new data with expiration time
    localStorage.setItem(BH_CACHE_KEY, JSON.stringify({ expiration: expirationTime, data: freshData }));

    return freshData;
}
import React from 'react';
import { IonButton } from '@ionic/react';



const EmailButton: React.FC = () => {
    const sendEmail = () => {
      const emailAddress = 'customerservice@trustisttransfer.com';
      const subject = 'TrustistTransfer Assistance Needed';
      const body = '';
  
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
  
      window.location.href = mailtoLink;
    };
  
    return (
      <IonButton  color={"secondary"}  onClick={sendEmail}>
        Email Support &#187;
      </IonButton>
    );
  };
  
  export default EmailButton;